import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import { WorkerPool } from "../workerPool";

import { Icon } from "@iconify/react";
import type { ImageFile } from "../../imageFiles";
import { FileStatus, imageTypes } from "../../imageFiles";
import useWizardStore from "../stores/useWizardStore";


export const UploadDropArea = ({
  onReady,
}) => {
  const newCaptureDate = new Date().toISOString();
  
  const importCaptureDate = useWizardStore(state => state.importCaptureDate);
  const setImportCaptureDate = useWizardStore(state => state.setImportCaptureDate);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const workCount = useRef(0);
  const scannedTypesOD = useRef(new Set<string>());
  const scannedTypesOS = useRef(new Set<string>());
  const imageFiles = useWizardStore(state => state.imageFiles);
  const updateImageFile = useWizardStore(state => state.updateImageFile);
  const addImageFiles = useWizardStore(state => state.addImageFiles);
  const clearImageFiles = useWizardStore(state => state.clearImageFiles);

  const workerCallback = (e: MessageEvent) => {
    console.log("Message received from worker", e.data);
    const { type, payload } = e.data;

    if (type === "scan") {
      --workCount.current;
      if (payload.eye == 'OD')
        scannedTypesOD.current.add(payload.type,);
      else if (payload.eye == 'OS')
        scannedTypesOS.current.add(payload.type,);
      updateImageFile(payload);
    }
  };

  const poolSize = 2; // Choose the desired pool size
  const workerPool = useRef<WorkerPool | null>(null);


  const handleFilesChange = (files: File[]) => {
    console.log("handleFilesChange", files);
    const filesArray = files.filter(f => supportedFileTypes.includes(f.type))
      .sort((a, b) => a.name.localeCompare(b.name));

    console.log("filesArray", filesArray);

    if (importCaptureDate === "") {
      setImportCaptureDate(newCaptureDate);
    }

    addImageFiles(filesArray.map(
      (file) =>
      ({
        name: file.name.replace(/\.[^.]+$/, ""),
        size: file.size,
        file_type: file.type,
        type: null,
        eye: null,
        capture_date: importCaptureDate || newCaptureDate,
        study_id: null,
        patient_id: null,
        file: file,
        lastModified: file.lastModified,
        status: FileStatus.Pending,
      } as ImageFile)
    ));

    setDroppedFiles(filesArray);

    handleSubmit(filesArray.length);
  }

  const handleSubmit = (dropCount: number) => {
    const dropSlice = imageFiles.slice(-dropCount);
    console.log("handleSubmit", dropSlice);
    dropSlice.forEach((imageFile) => {
      console.log("Submitting", imageFile);
      workerPool.current?.runTask({
        type: "scan",
        payload: imageFile,
      });
    });
  };

  useEffect(() => {
    if (droppedFiles.length > 0) {
      workCount.current += droppedFiles.length;
      onReady(false);
      handleSubmit(droppedFiles.length);
    }

  }, [droppedFiles]);

  useEffect(() => {
    if (workCount.current > 0)
      return;
    console.log(scannedTypesOD.current, scannedTypesOS.current);
    if (requiredImageTypes.every(t => scannedTypesOD.current.has(t) && scannedTypesOS.current.has(t)))
      onReady(true);
  }, [imageFiles]);

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    console.log("onDrop", e.dataTransfer.items, e.dataTransfer.files);
    e.preventDefault();
    let fileList: File[] = [];

    const readEntries = async (entry: FileSystemDirectoryEntry): Promise<File[]> => {
      return new Promise((resolve) => {
        const reader = entry.createReader();
        const entries: File[] = [];

        const readBatch = () => {
          reader.readEntries(async (batch) => {
            if (batch.length === 0) {
              resolve(entries);
            } else {
              for (const entry of batch) {
                if (entry.isFile) {
                  const file = await new Promise<File>((res) => (entry as FileSystemFileEntry).file(res));
                  entries.push(file);
                } else if (entry.isDirectory) {
                  const nestedFiles = await readEntries(entry as FileSystemDirectoryEntry);
                  entries.push(...nestedFiles);
                }
              }
              readBatch();
            }
          });
        };

        readBatch();
      });
    };

    const promises: Promise<File[]>[] = [];

    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      const item = e.dataTransfer.items[i];
      const entry = item.webkitGetAsEntry();

      if (entry) {
        if (entry.isFile) {
          const file = item.getAsFile();
          if (file) {
            fileList.push(file);
          }
        } else if (entry.isDirectory) {
          promises.push(readEntries(entry as FileSystemDirectoryEntry));
        }
      }
    }

    const directoryFiles = await Promise.all(promises);
    console.log('completed directoryFiles', directoryFiles);
    fileList = fileList.concat(...directoryFiles);

    console.log('fileList', fileList);
    handleFilesChange(fileList);
  };


  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };


  useEffect(() => {
    workerPool.current = new WorkerPool("filename", poolSize, workerCallback);

    console.log("init worker pool");
    let offscreens: OffscreenCanvas[] = [];
    for (let i = 0; i < poolSize; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = 496;
      canvas.height = 496;
      const offscreen = canvas.transferControlToOffscreen();
      offscreens.push(offscreen);
    }


    // Clean up on component unmount
    return () => {
      console.log("terminate worker pool");
      workerPool.current?.terminate();
    };
  }, []);

  function handleClickClear() {
    onReady(false);
    scannedTypesOD.current.clear();
    scannedTypesOS.current.clear();
    clearImageFiles();
  }

  return (
    <div className="flex flex-col items-stretch w-full">
      <div
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDropCapture={onDragLeave}
        className={`flex flex-col justify-center items-center border-2 rounded-md p-8 text-gray-800 transition-all duration-300 ease-in-out ${isDraggingOver
          ? "border-solid border-blue-600 bg-blue-100"
          : "border-dashed border-blue-300 bg-blue-50"
          }`}
      >
        <div className="text-xs">Import Date</div>
        <div className="flex items-center space-x-2">
          <input
            type="date"
            value={(importCaptureDate || newCaptureDate).split("T")[0]}
            onChange={(e) => setImportCaptureDate((new Date(e.target.value).toISOString()))}
            className="border-2 border-gray-300 rounded-md p-1 text-xs"
          />
        </div>
        <Icon
          icon="heroicons:cloud-arrow-up"
          className={`w-16 h-16 transition-all duration-300 ease-in-out transform  ${isDraggingOver
            ? "-translate-y-2 text-blue-600"
            : "translate-y-0 text-blue-300"
            }`}
        />
        <div className="font-semibold flex flex-col items-center justify-center">

          <div className="font-bold text-lg text-blue-600">
            Images
          </div>
          <div className="flex justify-end ">
            <div className={`whitespace-nowrap ${isDraggingOver ? "max-w-0 opacity-0" : "max-w-[78px] opacity-100"} transition-all duration-300 ease-in-out overflow-hidden `}>
              Drag &&nbsp;
            </div>
            Drop to Import
          </div>

          <label
            htmlFor="octImages"
            className="cursor-pointer flex- items-center justify-center mt-1.5"
          >
          </label>

        </div>

        <div className="flex flex-row items-center gap-2">
          <div className="text-xs text-[#1f2937] font-semibold">
            {imageFiles.filter((file) => file.status === FileStatus.Pending).length} Files Added
          </div>
          <button onClick={handleClickClear} disabled={imageFiles.length == 0} className="bg-red-500 text-white text-sm p-2 rounded-md disabled:opacity-50">Clear All</button>
        </div>
      </div>
    </div>
  );
};

const supportedFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/bmp",
];

const requiredImageTypes = [
  'OCT',
  'FAF',
  'FAFONH',
  // 'RED',
  'OCT Properties',
  'FAF Properties',
  'FAFONH Properties',
];

export type SliderProps = {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  step?: number;
  label?: string;
  disabled?: boolean;
  percentageDone?: number;
};
export const Slider = ({
  min,
  max,
  value,
  onChange,
  step = 1,
  label,
  disabled = false,
  percentageDone,
}: SliderProps) => {
  return (
    <div className="flex items-center relative">
      <input
        id="slider"
        type="range"
        className="w-2/3 z-10"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
      />
      <span className="w-1/3">{value}</span>

      <div className="absolute bottom-0 left-0 h-2 w-2/3">
        <div
          className="h-0.5 bg-green-500"
          style={{ width: `${percentageDone}%` }}
        ></div>
      </div>
    </div>
  );
};
