import React from 'react';
import { imageTypes } from '../../imageFiles';
import useWizardStore from '../stores/useWizardStore';

const ImageListTable = () => {
  const imageFiles = useWizardStore(state => state.imageFiles);

  if (imageFiles.length === 0) {
    return (
      <div className="flex justify-center items-center max-h-[200px] gap-x-1 text-xs w-[400px] p-[20px] border-blue-300 border-2 rounded-lg">
        <div>No images imported. Please drop images here.</div>
      </div>
    );
  }

  return (
    <div className='flex flex-col'>
      <div className="grid grid-cols-4 max-h-[200px] gap-x-1 text-xs w-[400px] p-[5px] border-blue-300 border-2 rounded-lg">
        <div className="font-bold">Image Type</div>
        <div className="font-bold text-right ">OD</div>
        <div className="font-bold text-right">OS</div>
        <div className="font-bold text-right">Unknown</div>
      </div>
      {imageTypes.map((imageType) => (
        <div key={imageType} className="grid grid-cols-4 max-h-[200px] gap-x-1 text-xs w-[400px] p-[5px] border-blue-300 border-2 rounded-lg">
          <div key={`${imageType}_type`}>{imageType}</div>
          <div key={`${imageType}_od`} className="text-right">{imageFiles.filter(i => i.type === imageType && i.eye === "OD").length}</div>
          <div key={`${imageType}_os`} className="text-right">{imageFiles.filter(i => i.type === imageType && i.eye === "OS").length}</div>
          <div key={`${imageType}_unknown`} className="text-right">{imageFiles.filter(i => i.type === imageType && (i.eye === null || !["OD", "OS"].includes(i.eye))).length}</div>
        </div>
      ))}
      <div className="grid grid-cols-4 max-h-[200px] gap-x-1 text-xs w-[400px] p-[5px] border-blue-300 border-2 rounded-lg">
        <div>Unknown</div>
        <div className="text-right">{imageFiles.filter(i => i.type === null && i.eye === "OD").length}</div>
        <div className="text-right">{imageFiles.filter(i => i.type === null && i.eye === "OS").length}</div>
        <div className="text-right" >{imageFiles.filter(i => i.type === null && (i.eye === null || !["OD", "OS"].includes(i.eye))).length}</div>
      </div>
    </div>
  );
}

export { ImageListTable };