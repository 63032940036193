import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
// import { unstable_batchedUpdates } from 'react-dom';

export const VesselMarker = ({
  initValue,
  id,
  x,
  y,
  r,
  onToggle,
}): JSX.Element => {

  const [isMarked, setIsMarked] = useState(initValue);

  function handleClick(e) {
    const tog = !isMarked;
    onToggle(id, tog, {x,y});
    e.stopPropagation();
    setIsMarked(tog);
  }

  return (
    <>
      <circle key={`mark ${id}`} cx={x} cy={y} r={r} strokeWidth={0} onClick={handleClick} />
      {isMarked && (<>
      <line key={`x1 ${id}`} x1={x-r} y1={y-r} x2={x+r} y2={y+r} style={{pointerEvents:'none'}} />
      <line key={`x2 ${id}`} x1={x+r} y1={y-r} x2={x-r} y2={y+r} style={{pointerEvents:'none'}} />
      </>)}
    </>
  );
};
