import React from 'react';
import { clinic_user_retinal_images, db } from "../../db";
import { useLiveQuery } from 'dexie-react-hooks';
import { imageTypes } from '../../imageFiles';
import useWizardStore from '../stores/useWizardStore';


const PreviewProcessedImageFiles = () => {
  const debug = useWizardStore(state => state.debug);
  const clinic_user_images = useLiveQuery(async () => {
    const rows = await db.clinic_user_retinal_images.toArray();

    const images = await db.filepath_to_png.toArray();

    console.log("rows", rows);
    console.log("images", images);

    type ImageRow = clinic_user_retinal_images & { png?: ArrayBuffer };

    let imageRows: ImageRow[] = [];

    rows.forEach((row) => {
      const png = images.find((image) => image.filepath === row.filepath);
      if (png) {
        imageRows.push({ ...row, png: png.png });
      }
    });

    return imageRows;
  }, []);

  console.log("clinic_user_images", clinic_user_images);

  if (!debug) return null;

  return (
    <div>
      <h1>Preview Processed Image Files</h1>
      {imageTypes.map((type) => (
        <div key={type}>
          <h2>{type}</h2>
          <div className='h-[140px] overflow-x-scroll flex gap-x-[10px]'>
            {clinic_user_images && clinic_user_images.filter(i => i.type === type).map((image) => (
              <div key={image.id}>
                <div className='text-[0.6rem]'>{image.filepath}</div>
                {image.png && <img src={URL.createObjectURL(new Blob([image.png]))} alt={image.filepath} className='max-h-[100px]' />}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export { PreviewProcessedImageFiles };