import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { db, getRedAndBscanSubImages } from "../../db";
import { useLiveQuery } from "dexie-react-hooks";
import { Layer, Line, Stage, Image } from "react-konva";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import useWizardStore, { BScanData } from "../../rewrite/stores/useWizardStore";
import { unstable_batchedUpdates } from "react-dom";
import { Coord } from "../../rewrite/types/LayoutTypes";

type OCTImagesProps = {
  capture_date: string;
  eye: "OD" | "OS";
  type: "OCT" | "OCTONH";
  onCalc: (data: BScanData) => void;
};

export const OCTImages = ({
  capture_date,
  eye,
  type,
  onCalc,
}: OCTImagesProps) => {
  const imageProperties = useWizardStore(state => state.imageProperties);
  const fovealPit = useWizardStore(state => state.fovealPit);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [posX, setPosX] = useState(0);
  const indexRef = useRef(0);
  const posRef = useRef(0);

  const mainDiv = useRef<HTMLDivElement>(null);
  const canvasEnfaceRef = useRef<Konva.Stage>(null);
  const canvasBscanRef = useRef<Konva.Stage>(null);
  const bscanWH = useRef({ w: 1536, h: 496 });

  const [cachedImageData, setCachedImageData] = useState<{
    filepath: String;
    redBitmap: ImageBitmap;
    bscanBitmap: ImageBitmap;
  }[]>([]);


  useEffect(() => {
    window.addEventListener('resize', fitCanvas);
    if (fovealPit[eye]) {
      indexRef.current = fovealPit[eye].scanIndex;
      posRef.current = fovealPit[eye].scanX;
      setSelectedIndex(indexRef.current);
      setPosX(posRef.current);
    }
    initAsync();
    return () => window.removeEventListener('resize', fitCanvas);
  }, []);

  async function initAsync() {
    const paths = await db.metaWhereUser().filter(r =>
      r.capture_date == capture_date
      && r.eye == eye
      && r.type == type)
      .primaryKeys() as string[];
    paths.sort();
    const cached = await Promise.all(paths.map(getRedAndBscanSubImages));
    unstable_batchedUpdates(() => {
      setCachedImageData(cached);
    });
  }

  const clickHandler = useCallback((e: KonvaEventObject<MouseEvent>) => {
    const location = e.target.getStage()!.getPointerPosition();
    e.target.getStage()!.x(0);
    e.target.getStage()!.y(0);
    if (!location) return;
    const x = location.x / canvasBscanRef.current!.scaleX();
    posRef.current = x;
    const coord = convertFovealPitCoordinates(x, indexRef.current, imageProperties![type][eye]);
    onCalc({
      scanIndex: indexRef.current,
      scanX: x,
      enfaceX: coord.x,
      enfaceY: coord.y,
    });
    setPosX(x);
  }, [imageProperties, type]);

  const sliderHandler = useCallback(e => {
    const index = parseInt(e.target.value);
    indexRef.current = index;
    setSelectedIndex(index);
  }, []);

  const fitCanvas = useCallback(() => {
    const aspect = bscanWH.current.h / bscanWH.current.w;
    const bscanCont = canvasBscanRef.current!.container();
    const enfaceCont = canvasEnfaceRef.current!.container();
    const flexW = Math.min(bscanWH.current.w, bscanCont.offsetWidth);
    const flexH = flexW * aspect;
    const scale = flexW / bscanWH.current.w;
    canvasBscanRef.current!.scale({ x: scale, y: scale });
    canvasEnfaceRef.current!.scale({ x: scale, y: scale });
    bscanCont.style.height = Math.trunc(flexH).toString()+'px';
    enfaceCont.style.height = Math.trunc(flexH).toString()+'px';
  }, []);

  useEffect(() => {
    if (cachedImageData.length < 1) return;
    canvasBscanRef.current!.container().style.overflow = 'hidden';
    fitCanvas();
  }, [cachedImageData]);

  if (cachedImageData.length < 1) {
    return <div>Loading...</div>;
  }

  const redX = posX * bscanWH.current.h / bscanWH.current.w;

  return (
    <div ref={mainDiv} className='flex gap-1'>
      <div className={`flex-auto overflow-hidden grow-[${bscanWH.current.h}]`}>
        <Stage
          ref={canvasEnfaceRef}
          width={bscanWH.current.h}
          height={bscanWH.current.h}
        >
          <Layer>
            <Image image={cachedImageData[selectedIndex].redBitmap} />
            <Line points={[redX, 0, redX, bscanWH.current.h]} stroke="lightgreen" opacity={0.5} />
          </Layer>
        </Stage>
      </div>
      <div className={`flex flex-col gap-1 flex-auto overflow-hidden grow-[${bscanWH.current.w}]`}>
          <Stage
            ref={canvasBscanRef}
            width={bscanWH.current.w}
            height={bscanWH.current.h}
            draggable
            onDragMove={clickHandler}
            onMouseDown={clickHandler}
          >
            <Layer listening={false}>
              <Image image={cachedImageData[selectedIndex].bscanBitmap} />
              <Line points={[posX, 0, posX, bscanWH.current.h]} stroke="lightgreen" />
            </Layer>
          </Stage>
        <input
          type="range"
          min={0}
          max={cachedImageData.length - 1}
          value={selectedIndex}
          onChange={sliderHandler}
        />
        <div className="text-xs text-center">
          Image {selectedIndex + 1} of {cachedImageData.length}
        </div>
      </div>
    </div>
  );
};

const convertFovealPitCoordinates = (
  x: number,
  imageIndex: number,
  imageProperties: any,
): Coord => {
  const ACTUAL_RED_IMAGE_SIZE = 496;

  const distance_um =
    (imageProperties.numBscans - 1) * imageProperties.distanceBetweenBscans;
  const distance_pixels =
    distance_um /
    imageProperties.IR.scaling /
    (imageProperties.sizeX / ACTUAL_RED_IMAGE_SIZE);
  const end_pos = (ACTUAL_RED_IMAGE_SIZE - distance_pixels) / 2;
  const start_pos = ACTUAL_RED_IMAGE_SIZE - end_pos;
  const slice_pos =
    start_pos -
    (distance_pixels / (imageProperties.numBscans - 1)) * imageIndex;

  const slider_pos = (x / imageProperties.sizeX) * ACTUAL_RED_IMAGE_SIZE;

  return { x: slider_pos, y: slice_pos };
};
