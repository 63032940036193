import { createContext } from "react";
import { ImageSyncService } from "./ImageSyncService";

export type MainContextType = {
  imageSyncService: ImageSyncService;
  isHome: boolean;
  patient_id: number;
  u: string;
  p: string;
  crc_name: string;
};

const MainContext = createContext<MainContextType | undefined>(undefined);

// const defaultMainContext = () => {
//   return {
//     imageSyncService: new ImageSyncService()
//   };
// }

export { MainContext };
