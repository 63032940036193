import React, { useEffect, useRef, useState } from "react";
import { createWorker, imageType, PSM } from "tesseract.js";
import useWizardStore from "../stores/useWizardStore";
import { Tab, Tabs } from "./Tabs";
import { useLiveQuery } from "dexie-react-hooks";
import { db, getImage, getImageData } from "../../db";
import { ImageRenderer } from "./ImageRenderer";

type FAFImageProperties = {
  scanAngle: number;
  sizeX: number;
  sizeY: number;
  scaling: number;
};

type OCTImageProperties = {
  scanAngle: number;
  sizeX: number;
  sizeZ: number;
  scalingX: number;
  scalingZ: number;
  numBscans: number;
  patternSize: { x: number, y: number };
  distanceBetweenBscans: number;
};


interface ImageData {
  id: string;
  type: "OCT Properties" | "OCTONH Properties" | "FAF Properties" | "FAFONH Properties";
  imageBitmap: ImageBitmap;
}


interface ImagePropertiesOCRProps {
  capture_date: string;
  eye: 'OD' | 'OS';
  types: string[];
}

const imageTypes = ['OCT', 'OCTONH', 'FAF', 'FAFONH'];
const imagePropertiesTypes = ['OCT Properties', 'OCTONH Properties', 'FAF Properties', 'FAFONH Properties'];
const imageTypeToProperties = {
  'OCT': 'OCT Properties',
  'OCTONH': 'OCTONH Properties',
  'FAF': 'FAF Properties',
  'FAFONH': 'FAFONH Properties',
};

export const ImagePropertiesOCR: React.FC<ImagePropertiesOCRProps> = ({ capture_date, eye, types = imagePropertiesTypes }) => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [imageTypeSelect, setImageTypeSelect] = useState(types[0]);

  // Fetch image data from the database
  const imageData = useLiveQuery(async () => {
    const fetchedImages: {
      [key: string]: ImageData;
    } = {};
    console.log('eye, captureDate', eye, capture_date);
    for (const type of types) {
      let ipType = imageTypeToProperties[type];
      const infoRow = await db.clinic_user_retinal_images.where("type").equals(ipType).filter((row) => row.eye === eye && row.capture_date === capture_date).first();

      console.log('infoRow', infoRow);

      if (!infoRow)
        continue;

      const image = await getImage(infoRow.filepath);

      console.log('image', image);

      if (!image)
        continue;

      if (image) {
        fetchedImages[type] = {
          id: infoRow.filepath,

          type: type as ImageData['type'],
          imageBitmap: image,
        };
      }
    }

    return fetchedImages;
  }, [capture_date, eye]);

  const [fafImageProperties, setFAFImageProperties] =
    useState<FAFImageProperties>({
      scanAngle: 0,
      sizeX: 0,
      sizeY: 0,
      scaling: 0,
    });

  const [fafonhImageProperties, setFAFONHImageProperties] =
    useState<FAFImageProperties>({
      scanAngle: 0,
      sizeX: 0,
      sizeY: 0,
      scaling: 0,
    });


  const [octImageProperties, setOCTImageProperties] =
    useState<OCTImageProperties>({
      scanAngle: 0,
      sizeX: 0,
      sizeZ: 0,
      scalingX: 0,
      scalingZ: 0,
      numBscans: 0,
      patternSize: { x: 0, y: 0 },
      distanceBetweenBscans: 0,
    });

  const [octonhImageProperties, setOCTONHImageProperties] =
    useState<OCTImageProperties>({
      scanAngle: 0,
      sizeX: 0,
      sizeZ: 0,
      scalingX: 0,
      scalingZ: 0,
      numBscans: 0,
      patternSize: { x: 0, y: 0 },
      distanceBetweenBscans: 0,
    });

  useEffect(() => {
    // reset values if new capture_date or eye
    setFAFImageProperties({
      scanAngle: 0,
      sizeX: 0,
      sizeY: 0,
      scaling: 0,
    });
    setFAFONHImageProperties({
      scanAngle: 0,
      sizeX: 0,
      sizeY: 0,
      scaling: 0,

    });
    setOCTImageProperties({
      scanAngle: 0,
      sizeX: 0,
      sizeZ: 0,
      scalingX: 0,
      scalingZ: 0,
      numBscans: 121,
      patternSize: { x: 30, y: 25 },
      distanceBetweenBscans: 60,
    });
    setOCTONHImageProperties({
      scanAngle: 0,
      sizeX: 0,
      sizeZ: 0,
      scalingX: 0,
      scalingZ: 0,
      numBscans: 121,
      patternSize: { x: 30, y: 25 },
      distanceBetweenBscans: 60,
    });
  }, [capture_date, eye]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      let imgURL = URL.createObjectURL(e.target.files[0]);
      setImage(imgURL);
      if (imageTypeSelect === "FAF") {
        runOCR(imgURL).then((text: string) => {
          const scanAngle = text.match(/Scan Angle (\d+)/)?.[1];
          const sizeX = text.match(/Size X (\d+)/)?.[1];
          const sizeY = text.match(/Size Y (\d+)/)?.[1];
          const scaling = text.match(/Scaling (\d+(\.\d+)?)/)?.[1];
          setFAFImageProperties((prevState) => ({
            ...prevState,
            scanAngle: Number(scanAngle),
            sizeX: Number(sizeX),
            sizeY: Number(sizeY),
            scaling: Number(scaling),
          }));
        });
      }
    }
  };

  async function runOCR(imgURL: string) {
    const worker = await createWorker("eng");
    await worker.setParameters({
      // tessedit_pageseg_mode: PSM.SINGLE_BLOCK,

      tessedit_char_whitelist: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ°. ', // if you know the expected characters
      tessjs_create_hocr: '0',
      tessjs_create_tsv: '0',
      tessjs_create_box: '0',
      tessjs_create_unlv: '0',
      tessjs_create_osd: '0',
      user_defined_dpi: '30',
    });
    const ret = await worker.recognize(imgURL);
    console.log(ret);
    await worker.terminate();
    return ret.data.text;
  }


  const runOCRForImage = async (image: ImageBitmap) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      const imgURL = canvas.toDataURL("image/jpeg");
      return runOCR(imgURL);
    }
  };



  useEffect(() => {
    for (const key in imageData) {
      if (imageData[key]) {
        runOCRForImage(imageData[key].imageBitmap).then((text: string | undefined) => {
          console.log('text', text);
          if (!text)
            return;

          if (imageData[key].type === "FAF Properties") {
            const scanAngle = text.match(/Scan Angle (\d+)/)?.[1];
            const sizeX = text.match(/Size X (\d+)/)?.[1];
            const sizeY = text.match(/Size Y (\d+)/)?.[1];
            const scaling = text.match(/Scaling (\d+(\.\d+)?)/)?.[1];
            console.log("scanAngle", scanAngle, "sizeX", sizeX, "sizeY", sizeY, "scaling", scaling);
            setFAFImageProperties((prevState) => ({
              ...prevState,
              scanAngle: parseFloat(scanAngle as string),
              sizeX: parseFloat(sizeX as string),
              sizeY: parseFloat(sizeY as string),
              scaling: parseFloat(scaling as string),
            }));
          }

          if (imageData[key].type === "FAFONH Properties") {
            const scanAngle = text.match(/Scan Angle (\d+)/)?.[1];
            const sizeX = text.match(/Size X (\d+)/)?.[1];
            const sizeY = text.match(/Size Y (\d+)/)?.[1];
            const scaling = text.match(/Scaling (\d+(\.\d+)?)/)?.[1];
            console.log("scanAngle", scanAngle, "sizeX", sizeX, "sizeY", sizeY, "scaling", scaling);
            setFAFONHImageProperties((prevState) => ({
              ...prevState,
              scanAngle: parseFloat(scanAngle as string),
              sizeX: parseFloat(sizeX as string),
              sizeY: parseFloat(sizeY as string),
              scaling: parseFloat(scaling as string),
            }));
          }

          if (imageData[key].type === "OCT Properties") {
            const scanAngle = text.match(/Scan Angle (\d+)/)?.[1];
            const sizeX = text.match(/Size X (\d+)/)?.[1];
            const sizeZ = text.match(/Size Z (\d+)/)?.[1];
            const scalingX = text.match(/Scaling X (\d+(\.\d+)?)/)?.[1];
            const scalingZ = text.match(/Scaling Z (\d+(\.\d+)?)/)?.[1];
            const numBscans = text.match(/Number of BScans (\d+)/)?.[1];
            const patternSize = text.match(/Pattern Size (\d+°)\s*x\s*(\d+\.?\d*°)/);
            const patternSizeX = patternSize?.[1];
            const patternSizeY = patternSize?.[2];
            const distanceBetweenBscans = text.match(/Distance between BScans (\d+)/)?.[1];
            console.log(distanceBetweenBscans);


            console.log("scanAngle", scanAngle, "sizeX", sizeX, "sizeZ", sizeZ, "scalingX", scalingX, "scalingZ", scalingZ);
            setOCTImageProperties((prevState) => ({
              ...prevState,
              scanAngle: parseFloat(scanAngle || '0'),
              sizeX: parseFloat(sizeX || '0'),
              sizeZ: parseFloat(sizeZ || '0'),
              scalingX: parseFloat(scalingX || '0'),
              scalingZ: parseFloat(scalingZ || '0'),
              numBscans: parseFloat(numBscans || '0'),
              patternSize: { x: parseFloat(patternSizeX || '0'), y: parseFloat(patternSizeY || '0') },
              distanceBetweenBscans: parseFloat(distanceBetweenBscans || '0'),
            }));
          }

          if (imageData[key].type === "OCTONH Properties") {
            const scanAngle = text.match(/Scan Angle (\d+)/)?.[1];
            const sizeX = text.match(/Size X (\d+)/)?.[1];
            const sizeZ = text.match(/Size Z (\d+)/)?.[1];
            const scalingX = text.match(/Scaling X (\d+(\.\d+)?)/)?.[1];
            const scalingZ = text.match(/Scaling Z (\d+(\.\d+)?)/)?.[1];
            const numBscans = text.match(/Number of BScans (\d+)/)?.[1];
            const patternSize = text.match(/Pattern Size (\d+°)\s*x\s*(\d+\.?\d*°)/);
            const patternSizeX = patternSize?.[1];
            const patternSizeY = patternSize?.[2];
            const distanceBetweenBscans = text.match(/Distance between BScans (\d+)/)?.[1];
            console.log(distanceBetweenBscans);

            console.log("scanAngle", scanAngle, "sizeX", sizeX, "sizeZ", sizeZ, "scalingX", scalingX, "scalingZ", scalingZ);
            setOCTONHImageProperties((prevState) => ({
              ...prevState,
              scanAngle: parseFloat(scanAngle || '0'),
              sizeX: parseFloat(sizeX || '0'),
              sizeZ: parseFloat(sizeZ || '0'),
              scalingX: parseFloat(scalingX || '0'),
              scalingZ: parseFloat(scalingZ || '0'),
              numBscans: parseFloat(numBscans || '0'),
              patternSize: { x: parseFloat(patternSizeX || '0'), y: parseFloat(patternSizeY || '0') },
              distanceBetweenBscans: parseFloat(distanceBetweenBscans || '0'),
            }));
          }
        });
      }
    }

  }, [imageData]);

  return (
    <Tabs>
      <Tab label="FAF-2">
        <div className="flex h-[500px]">
          <div className="w-1/2">
            <div className="grid grid-cols-2 gap-y-2">
              <div>Scan Angle:</div>
              <div>
                <input
                  type="number"
                  value={fafImageProperties.scanAngle}
                  onChange={(e) => {
                    setFAFImageProperties((prevState) => ({
                      ...prevState,
                      scanAngle: Number(e.target.value),
                    }));
                  }}
                />{" "}
                deg
              </div>
              <div>Size X:</div>
              <div>
                <input
                  type="number"
                  value={fafImageProperties.sizeX}
                  onChange={(e) => {
                    setFAFImageProperties((prevState) => ({
                      ...prevState,
                      sizeX: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Size Y:</div>
              <div>
                <input
                  type="number"
                  value={fafImageProperties.sizeY}
                  onChange={(e) => {
                    setFAFImageProperties((prevState) => ({
                      ...prevState,
                      sizeY: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Scaling:</div>
              <div>
                <input
                  type="number"
                  value={fafImageProperties.scaling}
                  onChange={(e) => {
                    setFAFImageProperties((prevState) => ({
                      ...prevState,
                      scaling: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers/pixel
              </div>
            </div>
          </div>
          <div className="w-1/2 overflow-y-scroll">
            <input
              type="file"
              accept="image/jpeg"
              ref={inputFile}
              onChange={onFileChange}
            />
            {image && <img alt="preview" src={image} />}
          </div>
        </div>
      </Tab>
      <Tab label="FAF-1M">
        <div className="flex h-[500px]">
          <div className="w-1/2">
            <div className="grid grid-cols-2 gap-y-2">
              <div>Scan Angle:</div>
              <div>
                <input
                  type="number"
                  value={fafonhImageProperties.scanAngle}
                  onChange={(e) => {
                    setFAFONHImageProperties((prevState) => ({
                      ...prevState,
                      scanAngle: Number(e.target.value),
                    }));
                  }}
                />{" "}
                deg
              </div>
              <div>Size X:</div>
              <div>
                <input
                  type="number"
                  value={fafonhImageProperties.sizeX}
                  onChange={(e) => {
                    setFAFONHImageProperties((prevState) => ({
                      ...prevState,
                      sizeX: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Size Y:</div>
              <div>
                <input
                  type="number"
                  value={fafonhImageProperties.sizeY}
                  onChange={(e) => {
                    setFAFONHImageProperties((prevState) => ({
                      ...prevState,
                      sizeY: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Scaling:</div>
              <div>
                <input
                  type="number"
                  value={fafonhImageProperties.scaling}
                  onChange={(e) => {
                    setFAFONHImageProperties((prevState) => ({
                      ...prevState,
                      scaling: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers/pixel
              </div>
            </div>
          </div>
          <div className="w-1/2 overflow-y-scroll">
            <input
              type="file"
              accept="image/jpeg"
              ref={inputFile}
              onChange={onFileChange}
            />
            {image && <img alt="preview" src={image} />}
          </div>
        </div>
      </Tab>
      <Tab label="OCT">
        <div className="flex h-[500px]">
          <div className="w-1/2">
            <div className="grid grid-cols-2 gap-y-2">
              <div>Scan Angle:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.scanAngle}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      scanAngle: Number(e.target.value),
                    }));
                  }}
                />{" "}
                deg
              </div>
              <div>Size X:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.sizeX}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      sizeX: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Size Z:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.sizeZ}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      sizeZ: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Scaling X:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.scalingX}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      scalingX: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers/pixel
              </div>
              <div>Scaling Z:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.scalingZ}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      scalingZ: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers/pixel
              </div>
              <div>Num Bscans:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.numBscans}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      numBscans: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div>Pattern Size X:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.patternSize.x}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      patternSize: { ...prevState.patternSize, x: Number(e.target.value) },
                    }));
                  }}
                />
              </div>
              <div>Pattern Size Y:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.patternSize.y}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      patternSize: { ...prevState.patternSize, y: Number(e.target.value) },
                    }));
                  }}
                />
              </div>
              <div>Distance Between Bscans:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.distanceBetweenBscans}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      distanceBetweenBscans: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers
              </div>
            </div>
          </div>
          <div className="w-1/2 overflow-y-scroll">
            <input
              type="file"
              accept="image/jpeg"
              ref={inputFile}
              onChange={onFileChange}
            />
            {image && <img alt="preview" src={image} />}
          </div>
        </div>
      </Tab>

      {/* <Tab label="ONH">
        <div className="flex h-[500px]">
          <div className="w-1/2">
            <div className="grid grid-cols-2 gap-y-2">
              <div>Scan Angle:</div>
              <div>
                <input
                  type="number"
                  value={octonhImageProperties.scanAngle}
                  onChange={(e) => {
                    setOCTONHImageProperties((prevState) => ({
                      ...prevState,
                      scanAngle: Number(e.target.value),
                    }));
                  }}
                />{" "}
                deg
              </div>
              <div>Size X:</div>
              <div>
                <input
                  type="number"
                  value={octonhImageProperties.sizeX}
                  onChange={(e) => {
                    setOCTONHImageProperties((prevState) => ({
                      ...prevState,
                      sizeX: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Size Z:</div>
              <div>
                <input
                  type="number"
                  value={octonhImageProperties.sizeZ}
                  onChange={(e) => {
                    setOCTONHImageProperties((prevState) => ({
                      ...prevState,
                      sizeZ: Number(e.target.value),
                    }));
                  }}
                />{" "}
                pixels
              </div>
              <div>Scaling X:</div>
              <div>
                <input
                  type="number"
                  value={octonhImageProperties.scalingX}
                  onChange={(e) => {
                    setOCTONHImageProperties((prevState) => ({
                      ...prevState,
                      scalingX: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers/pixel
              </div>
              <div>Scaling Z:</div>
              <div>
                <input
                  type="number"
                  value={octonhImageProperties.scalingZ}
                  onChange={(e) => {
                    setOCTONHImageProperties((prevState) => ({
                      ...prevState,
                      scalingZ: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers/pixel
              </div>
              <div>Num Bscans:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.numBscans}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      numBscans: Number(e.target.value),
                    }));
                  }}
                />
              </div>
              <div>Pattern Size X:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.patternSize.x}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      patternSize: { ...prevState.patternSize, x: Number(e.target.value) },
                    }));
                  }}
                />
              </div>
              <div>Pattern Size Y:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.patternSize.y}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      patternSize: { ...prevState.patternSize, y: Number(e.target.value) },
                    }));
                  }}
                />
              </div>
              <div>Distance Between Bscans:</div>
              <div>
                <input
                  type="number"
                  value={octImageProperties.distanceBetweenBscans}
                  onChange={(e) => {
                    setOCTImageProperties((prevState) => ({
                      ...prevState,
                      distanceBetweenBscans: Number(e.target.value),
                    }));
                  }}
                />{" "}
                micrometers
              </div>
            </div>
          </div>
          <div className="w-1/2 overflow-y-scroll">
           
            {image && <img alt="preview" src={image} />}
            {imageData && imageData[imageTypeSelect] && <ImageRenderer filepath={imageData[imageTypeSelect].id} />}
          </div>
        </div>
      </Tab> */}
      <div className="w-1/2 overflow-y-scroll">

        {image && <img alt="preview" src={image} />}
        {imageData && imageData[imageTypeSelect] && <ImageRenderer filepath={imageData[imageTypeSelect].id} />}
      </div>
    </Tabs>
  );
};
