import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import useWizardStore from '../stores/useWizardStore';
import { db, clinic_user_retinal_images } from '../../db';
import { ImageRenderer } from './ImageRenderer';

export const StudyEyeSelector = ({
  onSelect,
}) : JSX.Element => {
  const studyEye = useWizardStore(state => state.studyEye);
  const setStudyEye = useWizardStore(state => state.setStudyEye);

  const [octOD, setOCTOD] = useState<clinic_user_retinal_images>();
  const [octOS, setOCTOS] = useState<clinic_user_retinal_images>();

  useEffect(() => {
    initAsync();
  }, []);

  async function initAsync() {
    const odRow = await db.clinic_user_retinal_images
      .orderBy('filepath')
      .filter((row) => row.clinic_user_id == db.cachedUser && row.eye === 'OD' && row.type === 'OCT')
      .first();
    const osRow = await db.clinic_user_retinal_images
      .orderBy('filepath')
      .filter((row) => row.clinic_user_id == db.cachedUser && row.eye === 'OS' && row.type === 'OCT')
      .first();
    unstable_batchedUpdates(() => {
      if (odRow) setOCTOD(odRow);
      if (osRow) setOCTOS(osRow);
    });
  }

  function onClickOD() {
    onSelect('OD');
    setStudyEye('OD');
  }

  function onClickOS() {
    onSelect('OS');
    setStudyEye('OS');
  }

  if (!octOD || !octOS)
    return (<div>Loading...</div>);

  return (
    <div className='flex gap-4'>
      <div className={`flex flex-col gap-2 items-center p-1 border-blue-500 ${studyEye == 'OD' ? 'border-2' : ''}`}>
        <ImageRenderer key='OD' filepath={octOD.filepath} subImageKey='RED'/>
        <button onClick={onClickOD} disabled={studyEye=='OD'} className='bg-blue-500 text-white p-2 rounded-md disabled:opacity-50'>Select OD</button>
      </div>
      <div className={`flex flex-col gap-2 items-center p-1 border-blue-500 ${studyEye == 'OS' ? 'border-2' : ''}`}>
        <ImageRenderer key='OS' filepath={octOS.filepath} subImageKey='RED'/>
        <button onClick={onClickOS} disabled={studyEye=='OS'} className='bg-blue-500 text-white p-2 rounded-md disabled:opacity-50'>Select OS</button>
      </div>
    </div>
  );
};
