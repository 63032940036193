import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  // useContext,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import useWizardStore, {
} from '../../stores/useWizardStore';
import {
  LayoutRow,
  Coord,
  StimulusArray,
  getBounds,
  SVGtoDOMMatrix,
  getArrayColor,
  getStrokeColor,
  EditAction,
  TransformAction,
  getArrayStats,
  getDirection,
  generateSpindle,
  checkDistance,
} from '../../types/LayoutTypes';
import VisualFieldCanvas from './VisualFieldCanvas';
import TransformGroup from './TransformGroup';
import { RetinalOverlay } from './RetinalOverlay';
import { LabelSlider } from './LabelSlider';
import { CroppedImage } from './CroppedImage';
import { VesselMarker } from './VesselMarker';

interface LayoutEditorProps {
  eye: 'OD' | 'OS';
  steps: EditStep[];
  csvRows: LayoutRow[];
  bsc?: Coord;
  onComplete: (isComplete: boolean, edits?: EditAction[]) => void;
}
export const LayoutEditor = ({
  eye,
  steps,
  csvRows,
  bsc,
  onComplete,
}: LayoutEditorProps) : JSX.Element => {
  const imageProperties = useWizardStore(state => state.imageProperties);
  const imageConfig = useWizardStore(state => state.imageConfig);
  const [bscanIndex, setBscanIndex] = useState(0);
  const [blend, setBlend] = useState(0.8);
  const [step, setStep] = useState(0);
  const [didAction, setDidAction] = useState(false);
  const translateCoord = useRef<Coord>({ x: 0, y: 0 });
  const rotateDeg = useRef(0);

  const parentG = useRef<SVGGElement>(null);
  const myEdits = useRef<EditAction[]>([]);
  const [committedEdits, setCommittedEdits] = useState<string[]>([]);

  const bscanLine = useRef<SVGLineElement>(null);
  const bscanActive = useRef(false);
  const [redX, setRedX] = useState(-15);
  const windowAbort = useRef(new AbortController());

  const markerArrays = useRef<StimulusArray[]>([]);
  const [markers, setMarkers] = useState<{id:string, c:Coord}[]>([]);
  const [markersDone, setMarkersDone] = useState(false);
  const setVascularCoords = useWizardStore(state => state.setVascularCoords);

  const animDiv = useRef<HTMLDivElement>(null);


  const {
    targetRows,
  } = useMemo(() => {
    const lr = eye == 'OD' ? 'right' : 'left';
    const targetRows = new Map<string, LayoutRow[]>();
    for (const row of csvRows as LayoutRow[]) {
      if (!targetRows.has(row.array_id))
        targetRows.set(row.array_id, []);
      if (row.stimulus_eye == lr)
        targetRows.get(row.array_id)!.push(row);
    }
    return {
      targetRows,
    };
  }, [csvRows, eye]);

  const imgMemo = useMemo(() => {
    if (!imageProperties || !imageConfig[eye])
      return undefined;
    // reg was done in raw pixel space, as unfixed->fixed. so layers are scaled together, as unfixed->local
    const retinaCfg = imageConfig[eye]!;
    const scanAngle = imageProperties.OCT[eye]!.IR.scanAngle;
    const overlayScale = scanAngle / retinaCfg.RED.crop.width;
    const bCrop = retinaCfg.BSCAN.crop;
    return {
      scanAngle,
      overlayScale,
      retinaCfg,
      bCrop,
    };
  }, [imageProperties, imageConfig, eye]);

  useEffect(() => {
    const editedGroups = new Set(committedEdits.map(e => e.split(' ')[0]));
    const placed = editedGroups.size >= steps.filter(s => s.editType != 'mark').length;
    const done = placed && markersDone;
    if (!done)
      onComplete(false);
    else {
      setVascularCoords(eye, markers.map(m => m.c));
      onComplete(true, [...myEdits.current]);
    }
  }, [committedEdits, markers, markersDone, eye, csvRows, steps, onComplete]);

  const handleBlendSlider = useCallback((v: number) => {
    setBlend(v);
  }, []);
  const handleBscanSlider = useCallback((e) => {
    const v = parseInt(e.target.value);
    setBscanIndex(v);
  }, []);
  const handleMouseUp = useCallback(() => {
    bscanActive.current = false;
    windowAbort.current.abort();
  }, []);
  const handleBscan_MouseDown = useCallback((e: React.MouseEvent<SVGGElement>) => {
    bscanActive.current = true;
    window.addEventListener('mouseup', e => bscanActive.current = false, {signal: windowAbort.current.signal});
  }, []);
  const handleBscan_MouseMove = useCallback((e: React.MouseEvent<SVGGElement>) => {
    if (!bscanActive.current)
      return;
    const ox = e.nativeEvent.offsetX;
    bscanLine.current!.setAttribute('x1', ox.toString());
    bscanLine.current!.setAttribute('x2', ox.toString());
    const g = e.currentTarget as SVGGElement;
    const p = SVGtoDOMMatrix(g.getCTM()!.inverse()).transformPoint({ x: ox, y: 0 });
    const x = p.x / (OCT_WIDTH - imgMemo!.bCrop.x);
    const vx = x * imgMemo!.scanAngle;
    setRedX(vx);
  }, [imgMemo]);

  const onTranslate = useCallback((id: string, v: Coord | number, m: DOMMatrixReadOnly) => {
    const currentStep = steps[step];
    let edit = myEdits.current.find(e => e.id == id);
    if (!edit) {
      edit = {
        id: id,
        arrayType: currentStep.arrayType,
        action: {
          translation: v as Coord,
          matrix: m,
        },
      };
      myEdits.current.push(edit);
    }
    else {
      edit.action.translation = v as Coord;
      edit.action.matrix = m;
    }
    setDidAction(true);
  }, [steps, step]);

  const onRotate = useCallback((id: string, v: Coord | number, m: DOMMatrixReadOnly) => {
    const currentStep = steps[step];
    let edit = myEdits.current.find(e => e.id == id);
    if (!edit) {
      edit = {
        id: id,
        arrayType: currentStep.arrayType,
        action: {
          rotation: v as number,
          matrix: m,
        },
      };
      myEdits.current.push(edit);
    }
    else {
      edit.action.rotation = v as number;
      edit.action.matrix = m;
    }
    setDidAction(true);
  }, [steps, step]);

  function onMark(id: string, marked: boolean, c: Coord) {
    if (marked)
      setMarkers([...markers, { id, c }]);
    else setMarkers(markers.filter(m => m.id != id));
  }

  const edited: JSX.Element[] = useMemo(() => {
    if (targetRows.size < 1) return [];
    const editedGroups = new Set<string>();
    const edited: JSX.Element[] = [];
    if (bsc) {
      const a = rowsToArray('BS-C', targetRows.get('BS-C')!);
      // const moved = { x: bsc.x - a.coords[0].x, y: bsc.y - a.coords[0].y };
      if (myEdits.current.length == 0 || myEdits.current[0].arrayType != 'BS-C') {
        const act = {
          translation: {...bsc},
          matrix: new DOMMatrixReadOnly([1, 0, 0, 1, bsc.x, bsc.y]),
        };
        myEdits.current.unshift({
          id: 'BS-C',
          arrayType: 'BS-C',
          action: act,
        });
      }
      edited.push(renderStaticArray(a, myEdits.current[0].action));
    }
    markerArrays.current.length = 0;
    for (let i = 0; i < steps.length; i++) {
      const currentStep = steps[i];
      if (currentStep.editType == 'mark')
        continue;
      if (!committedEdits.some(e => e.startsWith(currentStep.arrayType)))
        break;
      editedGroups.add(currentStep.arrayType);
      const stepEdits = myEdits.current.filter(e => e.arrayType == currentStep.arrayType);
      const rows = targetRows.get(currentStep.arrayType)!;
      const grp = makeGroup(rowsToArray(currentStep.arrayType, rows));
      for (const a of grp) {
        if (a.id.startsWith('GA')) {
          const stats = getArrayStats(a.coords);
          const vec = getDirection(a.id.split('-')[1], eye);
          const spin3 = [...a.coords, ...generateSpindle(vec, stats.center, stats.interval).slice(-3)];
          let sid = Math.abs(a.members[0]);
          markerArrays.current.push({
            ...a,
            members: spin3.map(s => sid++),
            coords: spin3,
          });
        }
        else if (a.id.startsWith('BS')) markerArrays.current.push(a);
        let edit = stepEdits.find(e => a.id == e.arrayType);
        if (a.id == 'T')
          edit = stepEdits.find(e => e.id.substring(2) == a.members[0].toString());
        edited.push(renderStaticArray(a, edit ? edit.action : null));
      }
    }
    let add = 0;
    if (markersDone && steps.some(s => s.editType == 'mark'))
      add = 1;
    setStep(editedGroups.size + add);
    return edited;
  }, [committedEdits, markersDone, bsc, steps, eye, targetRows]);

  const editable: JSX.Element[] = useMemo(() => {
    const currentStep = steps[step];
    if (!currentStep || currentStep.editType == 'mark')
      return [];
    const editable: JSX.Element[] = [];
    translateCoord.current = { x: 0, y: 0 };
    rotateDeg.current = 0;
    const rows = targetRows.get(currentStep.arrayType)!;
    const arrays = makeGroup(rowsToArray(currentStep.arrayType, rows));
    for (const a of arrays) {
      let tag = a.id;
      if (a.id == 'T')
        tag += ` ${a.members[0]}`;
      switch (currentStep.editType) {
      case 'translate':
        editable.push(
          <TransformGroup key={tag} id={tag} mode={currentStep.editType} onUse={onTranslate}>
            {renderStaticArray(a, null)}
          </TransformGroup>
        );
        break;
      case 'rotate':
        editable.push(
          <TransformGroup key={tag} id={tag} mode={currentStep.editType} onUse={onRotate}>
            {renderStaticArray(a, null)}
          </TransformGroup>
        );
        break;
      }
    }
    return editable;
  }, [steps, step, targetRows, onTranslate, onRotate]);

  const currentStep = steps[step];
  let helpText = 'You have completed the layout for this eye! You may click Next at the bottom to proceed.';
  let helpDom = (<>{helpText}</>);
  let actionText = '';
  if (currentStep) {
    helpDom = (<>{currentStep.helpText}</>);
    if (currentStep.editType == 'translate')
      actionText = 'Click and drag the target item to move it.';
    else if (currentStep.editType == 'rotate')
      actionText = 'Click and drag the target item to rotate it around the grid origin.';
    else if (currentStep.editType == 'mark')
      actionText = 'Click any stimulus location to toggle markings.';
    if (currentStep.arrayType == 'T') //haxx
      helpDom = (<>Move T stimuli away from <b>GA</b> or major <b>blood vessels</b>.</>);
  }

  if (targetRows.size < 1 || !imageConfig.OD || !imageConfig.OS)
    return <div>Loading...</div>;

  const bUrl = imgMemo!.retinaCfg.BSCAN.urls[bscanIndex];
  const bCrop = imgMemo!.retinaCfg.BSCAN.crop;
  const bscanCt = imgMemo!.retinaCfg.BSCAN.blobs.length;

  const octSlider = (
    <div className="flex">
      <label htmlFor='slider-oct' className='text-right text-xs w-16 mr-1'>
        {`${bscanIndex+1} of ${bscanCt}`}
      </label>
      <input
        id='slider-oct'
        type="range"
        max={bscanCt-1}
        value={bscanIndex}
        onChange={handleBscanSlider}
        className="flex-1"
      />
    </div>
  );

  function handleOKClick(e) {
    if (step < 0 || step >= steps.length)
      return;
    if (currentStep.editType == 'mark') {
      setMarkersDone(true);
      setDidAction(false);
      animDiv.current!.className = 'animate-[flash-in_0.5s]';
      setTimeout(() => {
        animDiv.current!.className = '';
      }, 500);
      return;
    }
    let batch = myEdits.current.filter(e => e.arrayType == currentStep.arrayType);
    if (batch.length == 0) {
      const mx = new DOMMatrixReadOnly();
      const edit = {
        id: currentStep.arrayType,
        arrayType: currentStep.arrayType,
        action: {
          translation: { x: 0, y: 0 },
          matrix: mx,
          pmx: mx,
        },
      };
      myEdits.current.push(edit);
      batch = [edit];
    }
    setCommittedEdits([...committedEdits, ...batch.map(e => e.id)]);
    if (!steps.some(s => s.editType == 'mark'))
      setMarkersDone(true);
    setDidAction(false);
    animDiv.current!.className = 'animate-[flash-in_0.5s]';
    setTimeout(() => {
      animDiv.current!.className = '';
    }, 500);
  }

  function handleUndoClick(e) {
    if (step < 1)
      return;
    const rewindTypes: string[] = [];
    const prevStep = steps[step-1];
    if (currentStep?.editType == 'mark' || prevStep.editType == 'mark') {
      setMarkers([]);
      setMarkersDone(false);
    }
    if (currentStep && currentStep.editType != 'mark') rewindTypes.push(currentStep.arrayType);
    if (prevStep.editType != 'mark') rewindTypes.push(prevStep.arrayType);
    myEdits.current = myEdits.current.filter(e => !rewindTypes.includes(e.arrayType));
    setCommittedEdits(myEdits.current.map(e => e.id));
    setDidAction(false);
    animDiv.current!.className = 'animate-[flash-in_0.5s]';
    setTimeout(() => {
      animDiv.current!.className = '';
    }, 500);
  }

  const staticMarkers: JSX.Element[] = [];
  const clickableMarkers: JSX.Element[] = [];
  if (currentStep && currentStep.editType == 'mark') {
    for (const a of markerArrays.current) {
      const rad = a.stimDiameter / 2;
      const e = myEdits.current.find(e => e.id == a.id);
      for (let i = 0; i < a.coords.length; i++) {
        const c = {...a.coords[i]};
        if (e) {
          const p = e.action.matrix.transformPoint(c);
          c.x = p.x;
          c.y = p.y;
        }
        let marked = false;
        for (const m of markers) {
          marked = checkDistance(m.c, c, rad);
          if (marked) break;
        }
        clickableMarkers.push(<VesselMarker key={a.members[i]} initValue={marked} id={a.members[i]} x={c.x} y={c.y} r={rad} onToggle={onMark} />);
      }
    }
  }
  else {
    for (const m of markers) {
      const r = markerArrays.current[0].stimDiameter / 2;
      staticMarkers.push(<line key={`x1 ${m.id}`} x1={m.c.x-r} y1={m.c.y-r} x2={m.c.x+r} y2={m.c.y+r} />);
      staticMarkers.push(<line key={`x2 ${m.id}`} x1={m.c.x+r} y1={m.c.y-r} x2={m.c.x-r} y2={m.c.y+r} />);
    }
  }

  return (
    <div className='flex gap-2'>
      <div className='flex flex-col gap-1'>
        <VisualFieldCanvas width={600} height={600} pixelsPerDegree={10}>
          <g ref={parentG} transform='scale(10)'>
            <RetinalOverlay config={imgMemo!.retinaCfg} scanIndex={bscanIndex} bscanX={redX} scale={imgMemo!.overlayScale} blend={blend} />
            {edited}
            {editable}
            <g key='markers-interact' fill='transparent' stroke='white' strokeWidth={0.05}>
              {clickableMarkers}
              <g key='markers-static' style={{pointerEvents:'none'}}>
                {staticMarkers}
              </g>
            </g>
          </g>
        </VisualFieldCanvas>
        <LabelSlider id='blend' label='FAF Blend' initValue={80} onValue={handleBlendSlider} />
      </div>
      <div className='flex flex-col flex-1 gap-1'>
        <svg height={300}>
          <g transform={`scale(${300/bCrop.height})`} onMouseDown={handleBscan_MouseDown} onMouseUp={handleMouseUp} onMouseMove={handleBscan_MouseMove} onMouseLeave={handleMouseUp}>
            <CroppedImage x={bCrop.x} y={bCrop.y} w={bCrop.width} h={bCrop.height} url={bUrl} />
          </g>
          <line ref={bscanLine} x1={0} y1={0} x2={0} y2={300} stroke='#0f0' opacity={0.5} style={{ pointerEvents: "none" }} />
        </svg>
        {octSlider}
        <div ref={animDiv}>
          <article className='my-8'>
            <p className='text-wrap'>{step+1}. {helpDom}</p>
            {actionText && <p className='text-sm italic'>{actionText}</p>}
            <p className='text-sm italic'>Click and drag empty areas to pan the view. Scroll the mouse wheel to zoom the view.</p>
            <p className='text-sm italic'>Click and drag the B-scan to place a vertical line onto the infrared image.</p>
          </article>
          <div className='flex flex-row gap-1'>
            <button onClick={handleOKClick} disabled={step >= steps.length || (!didAction && currentStep.editType != 'mark') } className="bg-blue-500 text-white rounded-md p-4 disabled:opacity-50">{currentStep?.editType == 'mark' ? 'Confirm Markings' : ' Confirm Placement'}</button>
            <button onClick={handleUndoClick} disabled={step < 1} className="bg-teal-600 text-white rounded-md p-4 disabled:opacity-50">Undo</button>
          </div>
        </div>
      </div>
    </div>
  );
};




const OCT_WIDTH = 2032;

export interface EditStep {
  arrayType: string;
  editType: 'translate' | 'rotate' | 'mark';
  helpText: string;
}
export function getTrainingSteps(isStudyEye: boolean): EditStep[] {
  let steps: EditStep[] = [
    { arrayType: 'BS-C', editType: 'translate',
      helpText: 'Place BS-C in the center of the blindspot.' },
    { arrayType: 'T', editType: 'translate',
      helpText: 'Move T stimuli away from GA or major blood vessels.' }, //see haxx above
  ];
  return steps;
}
export function getLocalizerSteps(isStudyEye: boolean): EditStep[] {
  const sharedSteps: EditStep[] = [
    { arrayType: 'BS-S', editType: 'translate',
      helpText: 'Place the BS-S crossbar at the superior margin of the blindspot.' },
    { arrayType: 'BS-I', editType: 'translate',
      helpText: 'Place the BS-I crossbar at the inferior margin of the blindspot.' },
    { arrayType: 'BS-N', editType: 'translate',
      helpText: 'Place the BS-N crossbar at the nasal margin of the blindspot.' },
    { arrayType: 'BS-T', editType: 'translate',
      helpText: 'Place the BS-T crossbar at the temporal margin of the blindspot.' },
  ];
  if (!isStudyEye)
    return [
      ...sharedSteps,
      { arrayType: '', editType: 'mark',
        helpText: 'Mark any stimuli locations which are covered more than 50% by a blood vessel.' },
    ];
  return [
    ...sharedSteps,
    { arrayType: 'GA-S', editType: 'translate',
      helpText: 'Place the GA-S crossbar at the superior margin of the GA.' },
    { arrayType: 'GA-I', editType: 'translate',
      helpText: 'Place the GA-I crossbar at the inferior margin of the GA.' },
    { arrayType: 'GA-N', editType: 'translate',
      helpText: 'Place the GA-N crossbar at the nasal margin of the GA.' },
    { arrayType: 'GA-T', editType: 'translate',
      helpText: 'Place the GA-T crossbar at the temporal margin of the GA.' },
    { arrayType: '', editType: 'mark',
      helpText: 'Mark any stimuli locations (both GA and BS) which are covered more than 50% by a blood vessel.' },
    { arrayType: 'HP', editType: 'rotate',
      helpText: "Rotate the HP array until it is away from all other arrays. Avoid placing it near the horizontal axis." },
  ];
}



function makeGroup(a: StimulusArray) : StimulusArray[] {
  if (a.id != 'T')
    return [a];
  const result: StimulusArray[] = [];
  for (let i = 0; i < a.members.length; i++) {
    result.push({
      id: a.id,
      members: [a.members[i]],
      coords: [a.coords[i]],
      stimDiameter: a.stimDiameter,
      x: 0,
      y: 0,
    });
  }
  return result;
}

function rowsToArray(arrayID: string, rows: LayoutRow[]) : StimulusArray {
  const coords: Coord[] = rows.map(r => ({ x: r.stimulus_x_deg, y: r.stimulus_y_deg }));
  return {
    id: arrayID,
    members: rows.map(r => r.stimulus_location_id),
    coords: coords,
    stimDiameter: rows[0].stimulus_diameter_deg,
    x: 0,
    y: 0,
  };
}

function renderStaticArray(a: StimulusArray, transform: TransformAction | null) {
  let svgTransform = '';
  if (transform) {
    if (transform.translation)
      svgTransform = `translate(${transform.translation.x}, ${transform.translation.y})`;
    else if (transform.rotation)
      svgTransform = `rotate(${transform.rotation})`;
  }
  const { bounds, center: c, w, h, interval } = getArrayStats(a.coords);
  const labelx = c.x + (h > w ? -1 : 0);
  const labely = c.y + (h > w ? 0 : 1.2);
  let splitter: JSX.Element | undefined = undefined;
  if (/-[NTSI]/.test(a.id)) {
    const lineStart = {
      x: c.x + (h > w ? -1 : 0),
      y: c.y + (h > w ? 0 : -1),
    };
    const lineEnd = {
      x: c.x + (h > w ? 1 : 0),
      y: c.y + (h > w ? 0 : 1),
    };
    splitter = (<line x1={lineStart.x} y1={lineStart.y} x2={lineEnd.x} y2={lineEnd.y}/>);
  }
  let previews: JSX.Element[] = [];
  if (a.id.startsWith('GA-')) {
    const dir = getDirection(a.id.split('-')[1], a.members[0] < 0 ? 'OS' : 'OD');
    const spindle = generateSpindle(dir, c, interval);
    previews.push(<circle key={`${a.id}-ext`} cx={spindle[6].x} cy={spindle[6].y} r={a.stimDiameter/2}/>);
    previews.push(<circle key={`${a.id}-f1`} cx={spindle[7].x} cy={spindle[7].y} r={a.stimDiameter/2}/>);
    previews.push(<circle key={`${a.id}-f2`} cx={spindle[8].x} cy={spindle[8].y} r={a.stimDiameter/2}/>);
  }
  let tag = a.id;
  if (a.id == 'T')
    tag += ` ${a.members[0]}`;
  return (
    <g key={tag} transform={svgTransform} fill={getArrayColor(a.id)} stroke={getStrokeColor(a.id)} strokeWidth={0.05}>
      {splitter}
      <g fill='none' stroke='white'>
        {previews}
      </g>
      {a.coords.map((c, i) => (
        <circle key={a.members[i]} cx={c.x} cy={c.y} r={a.stimDiameter/2} />
      ))}
      <text fontSize={0.8} textAnchor={h > w ? 'end' : 'middle'} dominantBaseline='middle' strokeWidth={0.02} transform={`translate(${labelx},${labely})`}>{a.id}</text>
      <rect x={bounds.minX-1} y={bounds.minY-1} width={w+2} height={h+2} fill='transparent' strokeWidth={0} />
    </g>
  );
}
