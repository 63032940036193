import React from 'react';
import {
  Coord,
} from '../../types/LayoutTypes';
import { CroppedImage } from './CroppedImage';

const strokeWidthDeg = 0.05;

export interface LocData extends Coord {
  id: number;
  arrayID: string;
  diameter: number;
  isMarked?: boolean;
  fill?: string;
  stroke?: string;
}

export interface MarginData extends Coord {
  arrayID: string;
  vertical: boolean;
  stroke: string;
}

export function renderLoc(loc: LocData) {
  if (loc.fill && loc.stroke)
    return <circle key={loc.id} cx={loc.x} cy={loc.y} r={loc.diameter/2} fill={loc.fill} stroke={loc.stroke} />
  return <circle key={loc.id} cx={loc.x} cy={loc.y} r={loc.diameter/2} />
}

export function renderMargin(m: MarginData) {
  const x = m.vertical ? 0 : 1;
  const y = m.vertical ? 1 : 0;
  return <line key={m.arrayID} x1={m.x - x} y1={m.y - y} x2={m.x + x} y2={m.y + y} stroke={m.stroke} />
}

export function renderMark(loc: LocData) {
  const r = loc.diameter / 2;
  return <g key={'x '+loc.id}>
    <line key={`x1 ${loc.id}`} x1={loc.x-r} y1={loc.y-r} x2={loc.x+r} y2={loc.y+r} />
    <line key={`x2 ${loc.id}`} x1={loc.x+r} y1={loc.y-r} x2={loc.x-r} y2={loc.y+r} />
  </g>
}

export function renderCropped(url: string, crop) {
  return <CroppedImage url={url} x={crop.x} y={crop.y} w={crop.width} h={crop.height} />
}

export function renderFoveal(c: Coord) {
  return <g opacity={0.67} transform={`translate(${c.x}, ${c.y})`}>
    <line key='fovx' x1={0} y1={-0.5} x2={0} y2={0.5} strokeWidth={strokeWidthDeg} stroke='yellow' />,
    <line key='fovy' x1={-0.5} y1={0} x2={0.5} y2={0} strokeWidth={strokeWidthDeg} stroke='yellow' />,
  </g>
}

export function makeRegTransform(reg, children) {
  return <g transform={`translate(${reg.translation.x}, ${reg.translation.y}) rotate(${reg.rotation}) scale(${reg.scale})`}>
    {children}
  </g>
}
