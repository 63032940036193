import { db, filepath_to_png } from "../../db";

class ImageSyncService
{
  cachedAuth: VividAuthWithPatient;

  private static readonly VIVID_URL = "https://www.seevividly.com/api_localizer/";
  private static readonly VIVIDAPI_SYNC = "sync_retinal_images";
  private static readonly VIVIDAPI_UPLOAD = "upload_retinal_images";
  private static readonly LAMBDA_URL = "https://43vb5jhsi6ewen44757wphw5ym0xetia.lambda-url.us-east-1.on.aws/";
  private _subdirs: ImageTree<string>;
  private _presignedGet: S3PresignedGetInfo;
  private _presignedGetTime: number;
  private _presignedUpload: S3PresignedUploadInfo;
  private _presignedUploadTime: number;

  get subdirs() { return this._subdirs; }

  async syncVivid() : Promise<ImageBasicProps[]>
  {
    const body = {
      ...this.cachedAuth,
      files: []
    };
    const myRows = await db.metaWhereUser()
      .sortBy('filepath');
    console.log(`syncing from ${myRows.length} local rows`);
    body.files = myRows;
    const rows = await WebTools.apiPost<any>(ImageSyncService.VIVID_URL + ImageSyncService.VIVIDAPI_SYNC, body);
    const rowsToInsert = rows.map(({ id, timestamp, ...rest }) => rest);
    await db.clinic_user_retinal_images.bulkPut(rowsToInsert);
    console.log(`inserted ${rowsToInsert.length} remote rows`);
    return rowsToInsert.map(row => ({ type: row.type, eye: row.eye, filepath: row.filepath }));
  }

  async uploadVivid(filenames: string[]) : Promise<number>
  {
    const body = {
      ...this.cachedAuth,
      files: []
    };
    const myRows = await db.metaWhereUser()
      .sortBy('filepath');
    const uploadRows = myRows.filter(row => filenames.includes(row.filepath));
    console.log(`uploading ${uploadRows.length} local rows`);
    body.files = uploadRows;
    const results = await WebTools.apiPost<VividUploadResponse>(ImageSyncService.VIVID_URL + ImageSyncService.VIVIDAPI_UPLOAD, body);
    console.log(results);
    const numAdded = Object.values(results).filter(v => v == "INSERTED").length;
    const numSkipped = Object.values(results).filter(v => v == "EXISTS").length;
    const numErrors = Object.values(results).filter(v => v.startsWith("ERROR")).length;
    console.log(`Upload resulted in ${numAdded} added, ${numSkipped} skipped, ${numErrors} errors`);
    return numAdded;
  }

  async requestSubdirs() : Promise<ImageTree<string>>
  {
    // if (this._subdirs !== undefined)
    //   return this._subdirs;
    this._subdirs = (await WebTools.apiPost<SubdirResponse>(ImageSyncService.LAMBDA_URL, { method: "subdir" })).subdir_info;
    console.log("cached subdirs: ", this._subdirs);
    return this._subdirs;
  }

  async requestPresignedGet() : Promise<S3PresignedGetInfo>
  {
    // if (this._presignedGetTime && Date.now() - this._presignedGetTime < 3000)
    //   return this._presignedGet;
    const body = {
      ...this.cachedAuth,
      method: "sync",
      files: {}
    };
    const myRows = await db.metaWhereUser()
      .sortBy('filepath');
    // build files arg like ImageTree of filename:checksum
    for (const row of myRows) {
      const pngExists = (await db.filepath_to_png
        .where("filepath")
        .equals(row.filepath)
        .count()) > 0;
      if (!pngExists)
        continue;
      const baseType = row.type.replace(" Properties", "");
      if (!body.files[baseType])
        body.files[baseType] = { OS: {}, OD: {} };
      body.files[baseType][row.eye][row.filepath] = row.sha1;
    }
    console.log(body);
    this._presignedGet = await WebTools.apiPost<S3PresignedGetInfo>(ImageSyncService.LAMBDA_URL, body);
    this._presignedGetTime = Date.now();
    return this._presignedGet;
  }

  async requestPresignedUpload() : Promise<S3PresignedUploadInfo>
  {
    if (this._presignedUploadTime && Date.now() - this._presignedUploadTime < 3000)
      return this._presignedUpload;
    const body = {
      ...this.cachedAuth,
      method: "upload"
    };
    this._presignedUpload = (await WebTools.apiPost<PresignedUploadResponse>(ImageSyncService.LAMBDA_URL, body)).presigned_info;
    this._presignedUploadTime = Date.now();
    return this._presignedUpload;
  }

  async downloadS3(presignedInfo: S3PresignedGetInfo) : Promise<number>
  {
    const rowsToInsert: filepath_to_png[] = [];
    for (const img_type in presignedInfo) {
      for (const eye in presignedInfo[img_type]) {
        for (const filename in presignedInfo[img_type][eye]) {
          const url = presignedInfo[img_type][eye][filename];
          const bytes = await WebTools.getFile(url);
          console.log(`downloaded ${filename} from S3`);
          rowsToInsert.push({ clinic_user_id: this.cachedAuth.patient_id, filepath: filename, png: bytes });
        }
      }
    }
    if (rowsToInsert.length < 1)
      return 0;
    await db.filepath_to_png.bulkPut(rowsToInsert);
    console.log(`bulk inserted ${rowsToInsert.length} pngs`);
    return rowsToInsert.length;
  }

  async uploadS3(presignedInfo: S3PresignedUploadInfo, filenames: string[])
  {
    await this.requestSubdirs();
    const metaRows = await db.metaWhereUser()
      .and(row => filenames.includes(row.filepath))
      .sortBy('filepath');
    const pngs = {};
    for (const row of metaRows) {
      const pngRow = await db.filepath_to_png
        .where("filepath")
        .equals(row.filepath)
        .first();
      pngs[row.filepath] = pngRow.png as ArrayBuffer;
    }
    const kk = Object.keys(pngs);
    if (metaRows.length !== filenames.length || Object.keys(pngs).length !== filenames.length)
      throw new Error("File list inconsistent with database");
    console.log(`uploading ${metaRows.length} pngs`);

    for (const row of metaRows) {
      const baseType = row.type.replace(" Properties", "");
      const form = ImageSyncService.BuildAWSUploadForm(
        presignedInfo,
        row.filepath,
        this._subdirs[baseType][row.eye],
        row.sha1,
        pngs[row.filepath]
      );
      const response = await WebTools.fetch(presignedInfo.url, { method: "POST", body: form });
      console.log(`file uploaded (${row.filepath}) ${response.status} `, await response.text());
    }
  }

  static BuildAWSUploadForm(presignedInfo: S3PresignedUploadInfo,
    filename: string,
    subdir: string,
    sha1: string,
    png: ArrayBuffer)
  {
    const form = new FormData();
    for (const k in presignedInfo.fields) {
      if (k === "key") continue;
      form.append(k, presignedInfo.fields[k]);
    }
    form.append("Content-Type", "image/png");
    form.append("x-amz-meta-sha1", sha1);
    form.append("key", presignedInfo.fields.key.replace("${filename}", subdir + "/" + filename + ".png"));
    form.append("file", new Blob([png], { type: "image/png" }), filename);
    return form;
  }
}





class HttpError extends Error
{
  statusCode: number;
  constructor(status: number, message: string)
  {
    super(message);
    this.statusCode = status;
  }
}

class WebTools
{
  static async fetch(url: string, init?: RequestInit)
  {
    const response = await fetch(url, init);
    if (!response)
      throw new Error("No response on fetch");
    if (!response.ok) {
      const text = await response.text();
      throw new HttpError(response.status, text);
    }
    return response;
  }

  static async apiPost<T>(url: string, body: any, abort?: AbortSignal)
  {
    const init = {
      method: "POST",
      body: JSON.stringify(body),
    };
    if (abort)
      init['signal'] = abort;
    const response = await WebTools.fetch(url, init);
    return response.json() as T;
  }

  static async getFile(url: string)
  {
    const response = await WebTools.fetch(url);
    return response.arrayBuffer();
  }
}


interface ImageBasicProps {
  type: string;
  eye: string;
  filepath: string;
}

type ImageTree<T> = {
  [key: string]: {
    OD: T;
    OS: T;
  }
}

type VividUploadResponse = {
  [key: string]: string;
}

type SubdirResponse = {
  subdir_info: ImageTree<string>;
}

type S3PresignedGetInfo = ImageTree<{[key:string]: string}>;

type S3PresignedUploadInfo = {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    'x-amz-security-token': string;
    policy: string;
    signature: string;
  }
};

type PresignedUploadResponse = {
  presigned_info: S3PresignedUploadInfo;
}

interface VividAuth {
  u: string;
  p: string;
}

interface VividAuthWithPatient extends VividAuth {
  patient_id: number;
}

export {
  ImageSyncService,
  WebTools,
  HttpError,
  type ImageBasicProps,
  type ImageTree,
  type S3PresignedGetInfo,
  type S3PresignedUploadInfo,
};
