import React from "react";
import { useEffect, useRef } from "react";
import { db } from "../../db";

export interface ImageRendererProps {
  filepath: string;
  subImageKey?: "OCT" | "FAFONH" | "FAF" | "RED" | "OCTONH" | "BSCAN";
  onRendered?: (image: ImageData) => void;
}

export const ImageRenderer = ({ filepath, subImageKey, onRendered }: ImageRendererProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const render = async () => {
      const row = await db.clinic_user_retinal_images.where("filepath").equals(filepath).first();
      const pngRow = await db.filepath_to_png.where("filepath").equals(filepath).first();



      if (pngRow && row) {
        const subImages = row.sub_images;



        console.log("ImageRenderer -> subImages", subImages)
        const blob = new Blob([pngRow.png], { type: "image/png" });
        const bitmap = await createImageBitmap(blob);

        let [x, y, width, height] = [0, 0, bitmap.width, bitmap.height];

        if (subImageKey && row.sub_images[subImageKey]) {
          const subImage = row.sub_images[subImageKey];
          x = subImage.x;
          y = subImage.y;
          width = subImage.width;
          height = subImage.height;

          if (subImage.width === undefined) {
            width = bitmap.width - x;
          }

          if (subImage.height === undefined) {
            height = bitmap.height - y;
          }
        }

        if (canvasRef.current) {
          canvasRef.current.width = width;
          canvasRef.current.height = height;
          const ctx = canvasRef.current.getContext("2d");
          if (ctx) {
            ctx.drawImage(bitmap, x, y, width, height, 0, 0, width, height);
          }
        }
      }
    };
    render();
  }, [filepath, subImageKey]);

  return <canvas ref={canvasRef} />;
};

export interface LowresImageRendererProps {
  file: File;
  height: number;
}

export const LowresImageRenderer = ({ file, height }: LowresImageRendererProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const render = async () => {
      const imageBitmap = await createImageBitmap(file, { resizeHeight: height });
      // draw to canvas
      if (canvasRef.current) {
        canvasRef.current.width = imageBitmap.width;
        canvasRef.current.height = imageBitmap.height;
        const ctx = canvasRef.current.getContext("2d");
        if (ctx) {
          ctx.drawImage(imageBitmap, 0, 0);
        }
      }
    };
    render();
  }, [file, height]);

  return <canvas ref={canvasRef} />;
};