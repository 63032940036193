import React, {
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { MainContext } from "../contexts/MainContext";
import useWizardStore from "../stores/useWizardStore";
import { WorkerPool } from "../workerPool";


function ProcessImages({
  onFinished,
}) {
  const { patient_id } = useContext(MainContext)!;
  const imageFiles = useWizardStore(state => state.imageFiles);
  const updateImageFile = useWizardStore(state => state.updateImageFile);

  const workerPool = useRef<WorkerPool | null>(null);
  const poolSize = 4;

  const workerCallback = (e: MessageEvent) => {
    console.log("Message received from worker", e.data);

    const { type, payload } = e.data;

    if (type === "png") {

      updateImageFile(payload);
    }
  };

  useEffect(() => {
    workerPool.current = new WorkerPool("image", poolSize, workerCallback);

    console.log("init worker pool");
    let offscreens: OffscreenCanvas[] = [];
    for (let i = 0; i < poolSize; i++) {
      const canvas = document.createElement("canvas");
      canvas.width = 496;
      canvas.height = 496;
      const offscreen = canvas.transferControlToOffscreen();
      offscreens.push(offscreen);
    }

    workerPool.current.init(offscreens);
    if (!imageFiles.some((image) => image.checksum)) {
      // send the images to the worker pool
      for (const f of imageFiles) {
        if (!f.eye || !f.type) continue; // required fields after filename scan
        workerPool.current?.runTask({ type: "png", user: patient_id, payload: f });
      }
    }

    // Clean up on component unmount
    return () => {
      console.log("terminate worker pool");
      workerPool.current?.terminate();
    };
  }, []);

  const validFiles = imageFiles.filter(f => f.eye && f.type);
  const processedCt = validFiles.reduce((a, i) => i.checksum ? a + 1 : a, 0);

  useEffect(() => {
    const validCt = imageFiles.reduce((a, i) => (i.eye && i.type) ? a + 1 : a, 0);
    if (processedCt > 0 && processedCt >= validCt) {
      onFinished();
    }
  }, [imageFiles]);

  if (validFiles.length === 0)
    return <div>No images to process</div>;

  return (
    <div className="bg-gray-200 p-4">
      {/* Progress Bar */}
      <div className="w-full bg-gray-300 h-2">
        <div className="bg-blue-500 h-2" style={{
          width: `${100 * processedCt / validFiles.length}%`
        }} />
      </div>
      {/* <button className="btn bg-blue-500 text-white px-2 py-1 rounded-md" onClick={processImages} >Process Images</button> */}
    </div>
  );
}

export default ProcessImages;
