import React, { useRef, useState } from "react";
import { createWorker } from "tesseract.js";

export const OCRTest = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [textValue, setTextValue] = useState<string>("");

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setTextValue("Loading...");
      let imgURL = URL.createObjectURL(e.target.files[0]);
      setImage(imgURL);
      runOCR(imgURL).then((text: string) => {
        setTextValue(text);
      });
    }
  };

  async function runOCR(imgURL: string) {
    const worker = await createWorker("eng");
    const ret = await worker.recognize(imgURL);
    await worker.terminate();
    return ret.data.text;
  }

  return (
    <div className="flex flex-col gap-y-4 max-w-xl">
      <input
        type="file"
        accept="image/jpeg"
        ref={inputFile}
        onChange={onFileChange}
      />
      {image && (
        <>
          <img alt="preview" src={image} />
          <textarea
            className="border h-[500px] resize-none"
            readOnly
            value={textValue}
          />
        </>
      )}
    </div>
  );
};
