export class WorkerPool {
  pool: Worker[];
  queue: { data: any; resolve: (value?: unknown) => void }[];
  workerScript: string;
  onMessageCallback: (e: MessageEvent) => void;

  constructor(workerScript, poolSize, onMessageCallback) {
    this.pool = [];
    this.queue = [];
    this.workerScript = "";
    this.onMessageCallback = onMessageCallback;

    for (let i = 0; i < poolSize; i++) {
      let worker;
      if (workerScript === "filename")
        worker = new Worker(new URL("../filename.worker.ts", import.meta.url));
      if (workerScript === "image")
        worker = new Worker(new URL("../image.worker.ts", import.meta.url));
      if (workerScript === "renderer")
        worker = new Worker(new URL("../renderer.worker.ts", import.meta.url));
      worker.onerror = (e) => {
        console.error(
          `Error in worker ${i} with message ${e.message} at ${e.filename}:${e.lineno}`
        );
      };
      worker.onmessage = (e) => this._onWorkerMessage(e, worker);
      this.pool.push(worker);
    }
  }

  _onWorkerMessage(e, worker) {
    // call the callback if it's provided
    if (this.onMessageCallback) {
      this.onMessageCallback(e);
    }
    if (this.queue.length) {
      const nextTask = this.queue.shift();
      worker.postMessage(nextTask.data);
      nextTask.resolve(e.data);
    } else {
      this.pool.push(worker);
    }
  }

  init(offscreenCanvases) {
    this.pool.forEach((worker, i) =>
      worker.postMessage(
        { type: "init", payload: { canvas: offscreenCanvases[i] } },
        [offscreenCanvases[i]]
      )
    );
  }

  runTask(data, priority = false) {
    return new Promise((resolve) => {
      const availableWorker = this.pool.pop();
      if (availableWorker) {
        availableWorker.postMessage(data);
        resolve(0);
      } else {
        if (priority) {
          this.queue.unshift({ data, resolve });
        } else {
          this.queue.push({ data, resolve });
        }
      }
    });
  }

  terminate() {
    this.pool.forEach((worker) => worker.terminate());
    this.queue.length = 0;
  }
}
