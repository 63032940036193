import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext";
import useWizardStore from "../stores/useWizardStore";
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from "../../db";

const TestImageSync = () => {
  const { imageSyncService } = useContext(MainContext)!;

  const liveMetaCt = useLiveQuery(async () => {
    return await db.metaWhereUser().count();
  }, []);

  const livePngCt = useLiveQuery(async () => {
    return await db.pngWhereUser().count();
  }, []);


  let snap: string[] = [];


  async function snapLocal() {
    snap = await getRowPaths();
    console.log(`snapped ${snap.length} rows`);
  }

  async function getRowPaths() {
    return (await db.metaWhereUser().sortBy('filepath'))
      .map(row => row.filepath);
  }

  async function onCheckClicked() {
    console.log(`snapshot contains ${snap.length} rows`);
    console.log(snap);
    const check_now = await getRowPaths();
    console.log(`db currently contains ${check_now.length} rows`);
    console.log(check_now);
  }

  async function onSnapClicked() {
    snapLocal();
  }

  async function onPullClicked() {
    const vividPulled = await imageSyncService.syncVivid();
    snapLocal();
    if (vividPulled.length < 1)
      return;
    const s3Get = await imageSyncService.requestPresignedGet();
    const numGot = await imageSyncService.downloadS3(s3Get);
  }

  async function onPushClicked() {
    snap = [];
    const snap_now = await getRowPaths();
    if (snap_now.length <= snap.length) {
      console.log("no new rows to push");
      return;
    }
    const setOld = new Set(snap);
    const newPaths = snap_now.filter(x => !setOld.has(x));
    // const pre = await imageSyncService.requestPresignedUpload();
    // await imageSyncService.uploadS3(pre, newPaths);
    const vvNumAdded = await imageSyncService.uploadVivid(newPaths);
  }

  return (
    <div>
      <div>Local DB count (meta-rows): {liveMetaCt}</div>
      <div>Local DB count  (png-rows): {livePngCt}</div>
      <button className="bg-blue-800 text-blue-50 rounded-md p-4" onClick={onCheckClicked}>CHECK</button>
      <button className="bg-blue-800 text-blue-50 rounded-md p-4" onClick={onSnapClicked}>SNAP</button>
      <button className="bg-blue-800 text-blue-50 rounded-md p-4" onClick={onPullClicked}>PULL</button>
      <button className="bg-blue-800 text-blue-50 rounded-md p-4" onClick={onPushClicked}>PUSH</button>
    </div>
  );
};

export { TestImageSync };
