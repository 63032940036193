import React, { useRef, useEffect } from 'react';
import ZoomableSVGCanvas from './ZoomableSVGCanvas';

interface VisualFieldCanvasProps {
  width: number;
  height: number;
  pixelsPerDegree: number;
  children: React.ReactNode;
}

const VisualFieldCanvas: React.FC<VisualFieldCanvasProps> = ({
  width,
  height,
  pixelsPerDegree,
  children
}) => {

  return (
    <ZoomableSVGCanvas width={width} height={height}>
      <rect x="-100%" y="-100%" width="400%" height="400%" fill="#404040" />
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {children}
        <g opacity={0.5} fill="none" stroke='white' strokeWidth={pixelsPerDegree/20} style={{ pointerEvents: "none" }}>
          {renderPolarGrid(pixelsPerDegree)}
        </g>
      </g>
    </ZoomableSVGCanvas>
  );
};

export function renderPolarGrid(pixelsPerDegree: number) {
  const gridLines: JSX.Element[] = [];
  const maxRadius = 30; // 30 degrees
  const radialSteps = 6; // Every 5 degrees
  const angularSteps = 12; // Every 30 degrees
  const d1 = pixelsPerDegree;

  // Major axes
  gridLines.push(
    <line
      key="x"
      x1={-maxRadius * pixelsPerDegree * 2}
      y1={0}
      x2={maxRadius * pixelsPerDegree * 2}
      y2={0}
    />
  );
  gridLines.push(
    <line
      key="y"
      x1={0}
      y1={-maxRadius * pixelsPerDegree * 2}
      x2={0}
      y2={maxRadius * pixelsPerDegree * 2}
    />
  );

  // Radial lines
  for (let r = 0; r <= maxRadius; r += maxRadius / radialSteps) {
    gridLines.push(
      <circle
        key={`r${r}`}
        cx={0}
        cy={0}
        r={r * pixelsPerDegree}
      />
    );
  }

  // Angular lines
  for (let a = 0; a < 360; a += 360 / angularSteps) {
    if (a % 90 == 0) continue;
    const radians = (a * Math.PI) / 180;
    gridLines.push(
      <line
        key={`a${a}`}
        x1={5 * pixelsPerDegree * Math.cos(radians)}
        y1={5 * pixelsPerDegree * Math.sin(radians)}
        x2={maxRadius * pixelsPerDegree * Math.cos(radians)}
        y2={maxRadius * pixelsPerDegree * Math.sin(radians)}
      />
    );
  }

  gridLines.push(<line key='fovx' x1={0} y1={-d1} x2={0} y2={d1} stroke='red' />);
  gridLines.push(<line key='fovy' x1={-d1} y1={0} x2={d1} y2={0} stroke='red' />);

  return gridLines;
};

export default VisualFieldCanvas;