export const imageTypes = ["OCT", "FAF", "FAFONH", "RED", "OCTONH"];

export enum FileStatus {
  Pending = "pending",
  Processing = "processing",
  Skipped = "skipped",
  Done = "done",
  Error = "error",
}

export type ImageFile = {
  file: File;
  name: string;
  size: number;
  file_type: string;
  type: string | null;
  eye: string | null;
  capture_date: string | null;
  study_id: string | null;
  patient_id: string | null;
  blobURL?: string;
  checksum: string;
  sub_images: any;
  lastModified: number;
  status: FileStatus;
};

export const ImageTypeToSubImages = {
  OCT: {
    RED: {
      x: 0,
      y: 0,
      width: 496,
      height: 496,
    },
    BSCAN: {
      x: 496,
      y: 0,
      width: undefined,
      height: 496,
    },
  },
  OCTONH: {
    RED: {
      x: 0,
      y: 0,
      width: 496,
      height: 496,
    },
    BSCAN: {
      x: 496,
      y: 0,
      width: undefined,
      height: 496,
    },
  },
  FAF: {
    FAF: {
      x: 0,
      y: 0,
      width: 1536,
      height: 1536,
    },
  },
  FAFONH: {
    FAF: {
      x: 0,
      y: 0,
      width: 1536,
      height: 1536,
    },
  },
};
