import { create } from "zustand";
import { ImageFile } from "../../imageFiles";
import {
  Coord,
  EditAction,
  ImageConfig,
  LayoutRow,
  LocUpdate,
  ResultRow,
} from "../types/LayoutTypes";

interface WizardState {
  debug: boolean;

  allCaptureDates: string[];
  importCaptureDate: string;
  selectedCaptureDate: string;

  imageFiles: ImageFile[];
  imageConfig: {
    OD: ImageConfig | null;
    OS: ImageConfig | null;
  };

  // fields below go into localizer_data field in vvp_layouts table
  // ***
  imageProperties: ImageProperties | null;
  studyEye: "OD" | "OS" | null;
  registrations: ImageRegistration;
  fovealPit: {
    OD: BScanData | null
    OS: BScanData | null
  };
  // ****

  trainingEdits: {
    OD: EditAction[];
    OS: EditAction[];
  }
  localizerEdits: {
    OD: EditAction[];
    OS: EditAction[];
  }
  vascularCoords: {
    OD: Coord[];
    OS: Coord[];
  }
  prl: {
    OD: Coord;
    OS: Coord;
  }
  cyclo: {
    OD: number;
    OS: number;
  }
  trainingCSV: LayoutRow[];
  localizerCSV: LayoutRow[];
  homeCSV: LayoutRow[];
  localizerResult: ResultRow[];
}

interface WizardActions {
  setAllCaptureDates: (captureDates: string[]) => void;
  setImportCaptureDate: (captureDate: string) => void;
  setSelectedCaptureDate: (captureDate: string) => void;

  addImageFile: (imageFile: ImageFile) => void;
  addImageFiles: (imageFiles: ImageFile[]) => void;
  updateImageFile: (imageFile: ImageFile) => void;
  removeImageFile: (imageFile: ImageFile) => void;
  clearImageFiles: () => void;

  setImageConfig: (eye, imageConfig: ImageConfig) => void;

  setStudyEye: (studyEye: "OD" | "OS") => void;
  setOCTFovealPit: (eye, data: BScanData) => void;
  setImageRegistrations: (registrations: ImageRegistration) => void;
  setImageProperties: (properties: ImageProperties) => void;

  setFixedLookup: (eye: string, lookup: ImageLookup) => void;
  setRegistration: (type: string, eye: string, transform: ImageRegistrationTransform) => void;

  setTrainingEdits: (eye: string, trainingEdits: EditAction[]) => void;
  setLocalizerEdits: (eye: string, localizerEdits: EditAction[]) => void;
  setVascularCoords: (eye: string, vascularCoords: Coord[]) => void;
  setPRL: (eye: string, prl: Coord) => void;
  setCyclo: (eye: string, cyclo: number) => void;

  setTrainingCSV: (trainingCSV: LayoutRow[]) => void;
  setLocalizerCSV: (localizerCSV: LayoutRow[]) => void;
  setHomeCSV: (homeCSV: LayoutRow[]) => void;
  setLocalizerResult: (localizerResult: ResultRow[]) => void;
  buildLocalizerData: () => LocalizerData;

  getIsImagingReady: () => boolean;

  reset: () => void;
}

const initialWizardState: WizardState = {
  debug: true,
  allCaptureDates: [],
  importCaptureDate: "",
  selectedCaptureDate: "",
  imageFiles: [],
  imageConfig: {
    OD: null,
    OS: null,
  },
  // imageUrls: null,
  studyEye: null,
  registrations: null,
  fovealPit: {
    OD: null,
    OS: null,
  },
  imageProperties: {
    FAF: {
      OD: {
        scanAngle: 30,
        sizeX: 1536,
        sizeY: 1536,
        scaling: 5.78,
      },
      OS: {
        scanAngle: 30,
        sizeX: 1536,
        sizeY: 1536,
        scaling: 5.85,
      },
    },
    FAFONH: {
      OD: {
        scanAngle: 30,
        sizeX: 1536,
        sizeY: 1536,
        scaling: 5.78,
      },
      OS: {
        scanAngle: 30,
        sizeX: 1536,
        sizeY: 1536,
        scaling: 5.85,
      },
    },
    OCT: {
      OD: {
        IR: {
          scanAngle: 30,
          sizeX: 1536,
          sizeY: 1536,
          scaling: 5.64,
        },
        scanAngle: 30,
        sizeX: 1536,
        sizeZ: 496,
        scalingX: 5.64,
        scalingZ: 3.87,
        numBscans: 121,
        patternSize: { x: 30, y: 25 },
        distanceBetweenBscans: 60,
      },
      OS: {
        IR: {
          scanAngle: 30,
          sizeX: 1536,
          sizeY: 1536,
          scaling: 5.78,
        },
        scanAngle: 30,
        sizeX: 1536,
        sizeZ: 496,
        scalingX: 5.78,
        scalingZ: 3.87,
        numBscans: 121,
        patternSize: { x: 30, y: 25 },
        distanceBetweenBscans: 62,
      },
    },
    OCTONH: {
      OD: {
        IR: {
          scanAngle: 30,
          sizeX: 1536,
          sizeY: 1536,
          scaling: 5.64,
        },
        scanAngle: 30,
        sizeX: 1536,
        sizeZ: 496,
        scalingX: 5.64,
        scalingZ: 3.87,
        numBscans: 121,
        patternSize: { x: 30, y: 25 },
        distanceBetweenBscans: 60,
      },
      OS: {
        IR: {
          scanAngle: 30,
          sizeX: 1536,
          sizeY: 1536,
          scaling: 5.64,
        },
        scanAngle: 30,
        sizeX: 1536,
        sizeZ: 496,
        scalingX: 5.78,
        scalingZ: 3.87,
        numBscans: 121,
        patternSize: { x: 30, y: 25 },
        distanceBetweenBscans: 60,
      },
    },
  },
  trainingEdits: { OD: [], OS: [] },
  localizerEdits: { OD: [], OS: [] },
  vascularCoords: { OD: [], OS: [] },
  prl: { OD: new DOMPoint(), OS: new DOMPoint() },
  cyclo: { OD: 0, OS: 0 },
  trainingCSV: [],
  localizerCSV: [],
  homeCSV: [],
  localizerResult: [],
};

const useWizardStore = create<WizardState & WizardActions>()((set, get) => ({
  ...initialWizardState,

  setAllCaptureDates: (allCaptureDates) => set({ allCaptureDates }),
  setImportCaptureDate: (importCaptureDate) => set({ importCaptureDate }),
  setSelectedCaptureDate: (selectedCaptureDate) => set({ selectedCaptureDate }),

  addImageFile: (imageFile: ImageFile) =>
    set((state) => ({ imageFiles: [...state.imageFiles, imageFile] })),
  addImageFiles: (imageFiles: ImageFile[]) =>
    set((state) => ({ imageFiles: [...state.imageFiles, ...imageFiles] })),
  updateImageFile: (imageFile: ImageFile) =>
    set((state) => ({
      imageFiles: state.imageFiles.map((file) =>
        file.name === imageFile.name ? imageFile : file
      ),
    })),
  removeImageFile: (imageFile: ImageFile) => {
    set((state) => ({
      imageFiles: state.imageFiles.filter(
        (file) => file.name !== imageFile.name
      ),
    }));
  },
  clearImageFiles: () => set({ imageFiles: [] }),

  setImageConfig: (eye, imageConfig) => set(state => ({ imageConfig: { ...state.imageConfig, [eye]: imageConfig } })),

  setStudyEye: (studyEye) => set({ studyEye }),
  setOCTFovealPit: (eye, data) => set(state => ({ fovealPit: { ...state.fovealPit, [eye]: data } })),
  setImageRegistrations: (ImageRegistrations) => set({ registrations: ImageRegistrations }),
  setImageProperties: (properties) => set({ imageProperties: properties }),

  setFixedLookup: (eye, lookup) => {
    set(state => {
      const reg = state.registrations
        ? { ...state.registrations }
        : {
          FAF: { OD: null, OS: null },
          FAFONH: { OD: null, OS: null },
          FixedImage: { OD: null, OS: null },
        };
      reg.FixedImage[eye] = lookup;
      return { registrations: reg };
    });
  },

  setRegistration: (type, eye, transform) => {
    set(state => {
      const reg = { ...state.registrations };
      reg[type][eye] = transform;
      return { registrations: reg };
    });
  },

  setTrainingEdits: (eye, edits) => set(state => ({ trainingEdits: { ...state.trainingEdits, [eye]: edits } })),
  setLocalizerEdits: (eye, edits) => set(state => ({ localizerEdits: { ...state.localizerEdits, [eye]: edits } })),
  setVascularCoords: (eye, vascularCoords) => set(state => ({ vascularCoords: { ...state.vascularCoords, [eye]: vascularCoords } })),
  setPRL: (eye, prl) => set(state => ({ prl: { ...state.prl, [eye]: prl } })),
  setCyclo: (eye, cyclo) => set(state => ({ cyclo: { ...state.cyclo, [eye]: cyclo } })),

  setTrainingCSV: (trainingCSV) => set({ trainingCSV }),
  setLocalizerCSV: (localizerCSV) => set({ localizerCSV }),
  setHomeCSV: (homeCSV) => set({ homeCSV }),
  setLocalizerResult: (localizerResult) => set({ localizerResult }),
  // setCachedLocalizerData: (cachedLocalizerData) => set({ cachedLocalizerData }),
  buildLocalizerData: () => ({
    timestamp: Math.trunc(Date.now() / 1000),
    captureDate: get().selectedCaptureDate,
    studyEye: get().studyEye,
    registrations: get().registrations,
    fovealPit: get().fovealPit,
    filenames: get().imageConfig.OD == null ? [] : [...get().imageConfig.OD.filenames, ...get().imageConfig.OS.filenames],
  }),

  loadLocalizerData: () => {
    console.log("loadLocalizerData");
  },

  getIsImagingReady: () => isLocDataComplete(get().buildLocalizerData()),

  reset: () => set({ ...initialWizardState }),
}));




type FundusImageProperties = {
  scanAngle: number;
  sizeX: number;
  sizeY: number;
  scaling: number;
};

type OCTImageProperties = {
  IR: {
    scanAngle: number;
    sizeX: number;
    sizeY: number;
    scaling: number;
  }

  scanAngle: number;
  sizeX: number;
  sizeZ: number;
  scalingX: number;
  scalingZ: number;
  numBscans: number;
  patternSize: { x: number; y: number };
  distanceBetweenBscans: number;
};

export type ImageProperties = {
  FAF: {
    OD: FundusImageProperties | null;
    OS: FundusImageProperties | null;
  };
  FAFONH: {
    OD: FundusImageProperties | null;
    OS: FundusImageProperties | null;
  };
  OCT: {
    OD: OCTImageProperties | null;
    OS: OCTImageProperties | null;
  };
  OCTONH: {
    OD: OCTImageProperties | null;
    OS: OCTImageProperties | null;
  };
};

export type ImageRegistrationTransform = {
  translation: { x: number; y: number };
  rotation: number;
  scale: number;
};
export const getDefaultTransform = () => ({
  translation: { x: 0, y: 0 },
  rotation: 0,
  scale: 1,
});

export type ImageLookup = {
  filepath: string;
  eye: string;
  type: string;
  subImageKey?: string;
};

export type ImageRegistration = {
  FAF: {
    OD: ImageRegistrationTransform | null;
    OS: ImageRegistrationTransform | null;
  };
  FAFONH: {
    OD: ImageRegistrationTransform | null;
    OS: ImageRegistrationTransform | null;
  };
  FixedImage: {
    OD: ImageLookup | null;
    OS: ImageLookup | null;
  };
};
function isRegComplete(reg: ImageRegistration) : boolean {
  return reg.FixedImage.OD != null
    && reg.FixedImage.OS != null
    && reg.FAF.OD != null
    && reg.FAF.OS != null
    && reg.FAFONH.OD != null
    && reg.FAFONH.OS != null;
}

export interface BScanData {
  scanIndex: number;
  scanX: number;
  enfaceX: number;
  enfaceY: number;
}
function isFovealComplete(fov: { OD: BScanData; OS: BScanData; } ) : boolean {
  return fov.OD != null
    && fov.OD.scanX > 0
    && fov.OS != null
    && fov.OS.scanX > 0;
}

export interface LocalizerData {
  timestamp: number;
  captureDate: string;
  studyEye: "OD" | "OS" | null;
  registrations: ImageRegistration | null;
  fovealPit: {
    OD: BScanData | null;
    OS: BScanData | null;
  } | null;
  filenames: string[];
}
function isLocDataComplete(loc: LocalizerData) : boolean {
  return loc.studyEye != null
    && isRegComplete(loc.registrations)
    && isFovealComplete(loc.fovealPit);
}

export default useWizardStore;
