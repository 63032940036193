import React, {
  useState,
} from 'react';


interface LabelSliderProps {
  id: string;
  label: string;
  initValue: number;
  onValue: (value: number) => void;
}
export const LabelSlider = ({
  id,
  label,
  initValue,
  onValue,
}: LabelSliderProps): JSX.Element => {

  const [v, setV] = useState(initValue);

  function handleValue(value: number) {
    setV(value);
    onValue(value / 100);
  }

  return (
    <div key={id} className="flex">
      <label htmlFor={`slider-${id}`} className="mr-2">
        {label}:
      </label>
      <span className="w-12">{v}%</span>
      <input
        id={`slider-${id}`}
        type="range"
        value={v}
        onChange={e => handleValue(parseInt(e.target.value))}
        className="flex-1"
      />
    </div>
  );
};
