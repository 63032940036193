import React, {
  useState,
  useRef,
  useEffect,
  // useCallback,
} from 'react';
import { childToParentMatrix, Coord, SVGtoDOMMatrix } from '../../types/LayoutTypes';

interface TransformGroupProps {
  id: string;
  mode: 'translate' | 'rotate';
  onFrame?: (id: string, value: Coord | number) => void;
  onUse?: (id: string, value: Coord | number, ctm: DOMMatrixReadOnly) => void;
  children: React.ReactNode;
}
const TransformGroup = ({
  id,
  mode,
  onFrame,
  onUse,
  children,
}: TransformGroupProps) : JSX.Element => {

  const groupElement = useRef<SVGGElement>(null);
  const parentElement = useRef<SVGGraphicsElement>();
  const isDragging = useRef(false);
  const clickOffset = useRef<Coord>({ x: 0, y: 0 });
  const prevAngle = useRef(0);
  const translateRef = useRef<Coord>({ x: 0, y: 0 });
  const [ translate, setTranslate ] = useState<Coord>({ x: 0, y: 0 });
  const rotateRef = useRef(0);
  const [ rotate, setRotate ] = useState(0);

  const handleHover = useRef<((e:any) => void) | null>(null);
  const handleUnHover = useRef<((e:any) => void) | null>(null);
  const handleDragBegin = useRef<((e:any) => void) | null>(null);
  const handleDragEnd = useRef<((e:any) => void) | null>(null);
  const handleDrag = useRef<((e:any) => void) | null>(null);

  useEffect(() => {
    parentElement.current = groupElement.current!.parentElement as unknown as SVGGraphicsElement;
  }, []);

  if (!handleHover.current)
    handleHover.current = e => {
      groupElement.current!.style.cursor = 'move';
      e.stopPropagation();
    };

  if (!handleUnHover.current)
      handleUnHover.current = e => {
      groupElement.current!.style.removeProperty('cursor');
      e.stopPropagation();
    };

  if (!handleDragBegin.current)
    handleDragBegin.current = e => {
      isDragging.current = true;
      // group transform will use coords in its parent space
      const worldToParentMatrix = SVGtoDOMMatrix(parentElement.current!.getCTM()!).inverse();
      if (mode == 'translate') {
        // store worldspace offset of click from group origin
        const op = SVGtoDOMMatrix(groupElement.current!.getCTM()!).transformPoint(new DOMPoint());
        clickOffset.current = { x: e.nativeEvent.offsetX - op.x, y: e.nativeEvent.offsetY - op.y };
      }
      else if (mode == 'rotate') {
        const cp = worldToParentMatrix.transformPoint(new DOMPoint(e.nativeEvent.offsetX, e.nativeEvent.offsetY));
        prevAngle.current = Math.atan2(cp.y, cp.x) * 180 / Math.PI;
      }
      window.addEventListener('mouseup', handleDragEnd.current!);
      e.stopPropagation();
    };

  if (!handleDragEnd.current)
    handleDragEnd.current = e => {
      isDragging.current = false;
      window.removeEventListener('mouseup', handleDragEnd.current!);
      const cpm = childToParentMatrix(groupElement.current!, parentElement.current!);
      onUse?.(id, mode == 'translate' ? translateRef.current : rotateRef.current, cpm);
      e.stopPropagation();
    };

  if (!handleDrag.current)
    handleDrag.current = e => {
      if (!isDragging.current) return;
      const worldToParentMatrix = SVGtoDOMMatrix(parentElement.current!.getCTM()!).inverse();
      if (mode == 'translate') {
        // move group to pointer minus the click offset
        const wp = new DOMPoint(e.nativeEvent.offsetX - clickOffset.current.x, e.nativeEvent.offsetY - clickOffset.current.y);
        const p = worldToParentMatrix.transformPoint(wp);
        translateRef.current = { x: p.x, y: p.y };
        onFrame?.(id, translateRef.current);
        setTranslate(translateRef.current);
      }
      else if (mode == 'rotate') {
        const rp = worldToParentMatrix.transformPoint(new DOMPoint(e.nativeEvent.offsetX, e.nativeEvent.offsetY));
        const dragAngle = Math.atan2(rp.y, rp.x) * 180 / Math.PI;
        rotateRef.current = (rotateRef.current + dragAngle - prevAngle.current) % 360;
        prevAngle.current = dragAngle;
        onFrame?.(id, rotateRef.current);
        setRotate(rotateRef.current);
      }
      e.stopPropagation();
    };

  return (
    <g
      ref={groupElement}
      transform={mode == 'translate' ? `translate(${translate.x}, ${translate.y})`: `rotate(${rotate})`}
      style={{ pointerEvents: 'auto' }}
      onMouseEnter={handleHover.current}
      onMouseLeave={handleUnHover.current}
      onMouseDown={handleDragBegin.current}
      onMouseUp={handleDragEnd.current}
      onMouseMove={handleDrag.current}>
      {/* <rect ref={invisBox} fill='transparent' /> */}
      {children}
    </g>
  );
};

export default TransformGroup;
