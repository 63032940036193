import React, {
} from 'react';
import {
  Coord,
  ImageConfig,
} from '../../types/LayoutTypes';
import {
  makeRegTransform,
  renderCropped,
  renderFoveal,
} from './elements';
import { CroppedImage } from './CroppedImage';


interface RetinalOverlayProps {
  config: ImageConfig;
  scanIndex: number;
  bscanX: number;
  scale: number;
  blend: number;
}
export const RetinalOverlay = ({
  config,
  scanIndex,
  bscanX,
  scale,
  blend,
}: RetinalOverlayProps): JSX.Element => {

  const fovDeg: Coord = {
    x: config.RED.fovea.x * scale,
    y: config.RED.fovea.y * scale,
  };

  return (
    <g transform={`translate(${-fovDeg.x}, ${-fovDeg.y})`}>
      <g transform={`scale(${scale})`}>
        {renderCropped(config.BSCAN.urls[scanIndex], config.RED.crop)}
        <g opacity={blend}>
          {makeRegTransform(config.FAFONH.reg, renderCropped(config.FAFONH.url, config.FAFONH.crop))}
          {makeRegTransform(config.FAF.reg, renderCropped(config.FAF.url, config.FAF.crop))}
        </g>
      </g>
      <line x1={bscanX} y1={0} x2={bscanX} y2={30} strokeWidth={0.05} stroke='#ff0' opacity={0.5} />
      {renderFoveal(fovDeg)}
    </g>
  );
};
