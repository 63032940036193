import React, { useState, useRef, useEffect } from "react";

interface TabProps {
  label: string;
  secondaryLabels?: string[];
}

const Tab: React.FC<TabProps> = ({ label, children, secondaryLabels }) => {
  return <>{children}</>;
};

const Tabs: React.FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabWidths, setTabWidths] = useState<number[]>([]);
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  // Filter children to include only Tab elements
  const tabChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === Tab
  );

  // useEffect(() => {
  //   const widths = tabRefs.current.map((ref) => ref?.getBoundingClientRect().width || 0);
  //   setTabWidths(widths);
  // }, [children]);

  const handleResize = () => {
    const widths = tabRefs.current.map((ref) => ref?.getBoundingClientRect().width || 0);
    setTabWidths(widths);
  };

  // use event listeners to update the tab widths (from the button)
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [children]);


  return (
    <div className="flex flex-col p-2 bg-gray-200 rounded-md">
      <div className="flex gap-x-2">
        {tabChildren.map((child, index) => (
          <button
            key={index}
            className={`font-semibold transition-all ${activeTab === index ? "text-blue-600" : "text-black"}`}
            ref={(el) => (tabRefs.current[index] = el)}
            onClick={() => handleTabClick(index)}
          >

            <div className="flex justify-between gap-x-[5px] text-[0.6rem] w-full">
              {React.isValidElement(child) && child.props.secondaryLabels && child.props.secondaryLabels.map((label, i) => (
                <div key={i}>{label}</div>
              ))}
            </div>
            <div>{React.isValidElement(child) && child.props.label}</div>
          </button>
        ))}
      </div>
      <div className="h-[2px] bg-blue-600 relative transition-all" style={{
        transform: `translateX(${tabWidths.slice(0, activeTab).reduce((acc, width) => acc + width, 8 * activeTab)}px)`,
        width: `${tabWidths[activeTab] || 0}px`
      }} />
      <div>{tabChildren[activeTab]}</div>
    </div>
  );
};

export { Tabs, Tab };
